import { renderAsset } from '@jotforminc/enterprise-promotions-utils';
import { AdvertisementLeadFlow } from '../components';

// A/B Test: epAdLeadFlow
const renderAdvertisementLeadFlow = props => {
  renderAsset({
    Asset: AdvertisementLeadFlow,
    rootElementId: 'advertisement-lead-flow-root',
    ...props
  });
};

export default renderAdvertisementLeadFlow;
