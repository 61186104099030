import featuredIntegrationsLinks from './subLinks/featuredIntegrations';
import paymentLinks from './subLinks/payment';
import storageLinks from './subLinks/storage';
import emailLinks from './subLinks/email';
import crmLinks from './subLinks/crm';
import { MENU_TYPES } from '../../constants';

const INTEGRATIONS = {
  FEATURED_INTEGRATIONS: 'featuredIntegrations',
  CRM: 'crm',
  STORAGE: 'storage',
  PAYMENT: 'payment',
  EMAIL: 'email'
};

const integrationsMenu = {
  name: 'integrations',
  menuTitle: 'INTEGRATIONS',
  seeAll: {
    title: 'See all integrations',
    url: '/integrations/'
  },
  menuItems: [
    // featured
    {
      name: INTEGRATIONS.FEATURED_INTEGRATIONS,
      title: 'INTEGRATIONS',
      subTitle: 'FEATURED INTEGRATIONS',
      seeMore: {
        title: 'See more',
        url: '/integrations/'
      },
      subLinks: featuredIntegrationsLinks
    },
    // crm
    {
      name: INTEGRATIONS.CRM,
      title: 'CRM',
      subTitle: 'CRM',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/crm'
      },
      subLinks: crmLinks
    },
    // storage
    {
      name: INTEGRATIONS.STORAGE,
      title: 'Storage',
      subTitle: 'STORAGE',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/file-sharing-and-storage'
      },
      subLinks: storageLinks
    },
    // payment
    {
      name: INTEGRATIONS.PAYMENT,
      title: 'Payment',
      subTitle: 'PAYMENT',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/payment-processing'
      },
      subLinks: paymentLinks
    },
    // email
    {
      name: INTEGRATIONS.EMAIL,
      title: 'Email',
      subTitle: 'EMAIL',
      seeMore: {
        title: 'See more',
        url: '/integrations/category/emailing'
      },
      subLinks: emailLinks
    }
  ]
};

const integrationsNavItem = {
  name: 'integrations',
  menuProps: integrationsMenu,
  linkProps: {
    renderAs: 'button',
    title: 'Integrations',
    menuType: MENU_TYPES.INTEGRATIONS
  }
};

export {
  INTEGRATIONS,
  integrationsNavItem
};
