const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE '); // Detects IE 10 or older
  const trident = ua.indexOf('Trident/'); // Detects IE 11
  const edge = ua.indexOf('Edge/'); // Detects old Edge

  return msie > -1 || trident > -1 || edge > -1;
};

const isWindows = () => window.navigator.userAgent.includes('Windows');

export const isYandex = () => {
  const ua = window.navigator.userAgent;
  const yandexIndex = ua.search(/YaBrowser/);
  return yandexIndex > 0 ? yandexIndex.toString() : '';
};

export const addBrowserClassNames = () => {
  const htmlEl = document.querySelector('html');
  const jfHeader = document.querySelector('.jfHeader');
  const ieClassName = `header-browser-ie-${isIE()}`;
  const yandexClassName = `header-browser-yandex-${isYandex()}`;

  if (isIE()) {
    htmlEl.classList.add('header-browser-ie');
    htmlEl.classList.add(ieClassName);
  }

  if (isYandex()) {
    htmlEl.classList.add('header-browser-yandex');
    htmlEl.classList.add(yandexClassName);
  }

  if (isWindows()) {
    jfHeader?.classList.add('windows');
  }
};
