export const PRODUCT = {
  // modals
  ADMIN_CONSOLE_DARK_MODAL: 'adminConsoleDarkModal',
  ASSIGN_TO_ORGANIZATON_MODAL: 'assignToOrganizationModal',
  CONTACT_DEDICATED_SUPPORT_MODAL: 'contactDedicatedSupportModal',
  ENTERPRISE_PROPERTY_MODAL: 'enterprisePropertyModal',
  ENTERPRISE_TEAMS_MODAL: 'enterpriseTeamsModal',
  TEAMS_MODAL: 'teamsModal',
  FOLDER_LIMIT_MODAL: 'folderLimitModal',
  HELP_DEDICATED_SUPPORT_MODAL: 'helpDedicatedSupportModal',
  TEAM_CREATION_LIMIT_MODAL: 'teamCreationLimitModal',
  WHITE_LABELING_MODAL: 'whiteLabelingModal',
  APPEXCHANGE_MODAL: 'appexchangeModal',
  ENTERPRISE_BEGINNERS_BOOK_MODAL: 'enterpriseBeginnersBookModal',
  ENTERPRISE_DATA_CENTERS_MODAL: 'enterpriseDataCentersModal',
  PDF_DOWNLOAD_MODAL: 'pdfDownloadModal',
  // banners
  ADD_AND_MANAGE_USERS_BANNER: 'addAndManageUsersBanner',
  CUSTOM_DOMAIN_API_BANNER: 'customDomainApiBanner',
  FORM_CUSTOM_DOMAIN_TOOLTIP: 'formCustomDomainTooltip',
  CUSTOM_DOMAIN_TOOLTIP_LEGACY: 'customDomainTooltipLegacy',
  TEAMS_CUSTOM_DOMAIN_LEGACY_TOOLTIP: 'teamsCustomDomainLegacyTooltip',
  DEDICATED_SUPPORT_BANNER: 'dedicatedSupportBanner',
  GENERAL_FAQ_PROF_SERVICES_BANNER: 'generalFaqProfessionalServicesBanner',
  LIMIT_BANNER: 'limitBanner',
  LOCAL_DATA_RESIDENCY_BANNER: 'localDataResidencyBanner',
  SESSIONS_ACCOUNT_SHARING_BANNER: 'sessionsAccountSharingBanner',
  SSO_ACCESS_LEGACY_BANNER: 'ssoAccessLegacyBanner',
  SSO_LEGACY_TOOLTIP: 'ssoLegacyTooltip',
  SSO_PREFILL_LEGACY_BANNER: 'ssoPrefillLegacyBanner',
  USER_GUIDE_PROF_SERVICES_BANNER: 'userGuideProfessionalServicesBanner',
  WHITE_LABELING_BANNER: 'whiteLabelingBanner',
  APPROVAL_WHITE_LABELING_BANNER: 'approvalWhiteLabelingBanner',
  WORKFLOW_GROUP_APPROVAL_BANNER: 'workflowGroupApprovalBanner',
  BLOG_EDUCATION_SIDE_BANNER: 'blogEducationSideBanner',
  PDF_DOWNLOAD_BANNER: 'pdfDownloadBanner',
  // sections
  MY_ACCOUNT_USERS_SECTION_WITH_FORM: 'myAccountUsersSection', // old: myAccountUsersSectionWithForm
  ENTERPRISE_DATA_CENTERS_SECTION: 'enterpriseDataCentersSection',
  DEDICATED_SUPPORT_SECTION: 'dedicatedSupportSection',
  // buttons
  ADMIN_CONSOLE_PROMOTION_BUTTON: 'adminConsolePromotionButton'
};
