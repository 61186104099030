import { createLinkElement, createScriptElement } from '../dom';
import { loadByApplyingLazyIgnoredElements } from './loadByApplyingLazyIgnoredElements';

export const loadAfter = (params, forceLoad = false) => {
  if (!Array.isArray(params)) {
    console.error('LoadAfter :: please give array to window.loadAfter function');
    return;
  }

  const defaultOptions = {
    type: '',
    url: '',
    callback: '',
    el: '',
    attr: '',
    force: false
  };

  params.forEach((param, index) => {
    const options = { ...defaultOptions, ...param };

    if (!options.url) {
      console.error('LoadAfter :: url not found');
      return;
    }

    const uniqueName = `${options.url.split('/').pop().split('.')[0]}${options.force ? index : ''}`;

    const elGeneralFunc = () => {
      if (document.body.classList.contains(uniqueName)) return;
      document.body.classList.add(uniqueName);

      if (options.type === 'JS') createScriptElement(options.url, options.callback, options.attr);
      if (options.type === 'CSS') createLinkElement(options.url, options.attr);
    };

    if (options.el) {
      const element = document.querySelector(options.el);
      if (!element) return;

      const handleEvent = event => {
        if (document.body.classList.contains(uniqueName)) return;
        loadByApplyingLazyIgnoredElements(event, elGeneralFunc);
      };

      if ('ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0) {
        element.addEventListener('touchstart', handleEvent);
      }
      element.addEventListener('mousemove', handleEvent);
      window.addEventListener('scroll', elGeneralFunc);

      if (forceLoad) elGeneralFunc();
      return;
    }

    elGeneralFunc();
  });
};

window.loadAfter = loadAfter;
window.dispatchEvent(new global.window.CustomEvent('jfheader-utils-ready'));
