import { CONTACT_SALES_AB_TEST_VARIATIONS, THEME_MAP } from '../constants';

export const isDevelopment = global?.JOTFORM_ENV === 'DEVELOPMENT';
export const isTesting = global?.JOTFORM_ENV === 'TESTING';

const ACTION_KEYS = {
  ENTER: 'Enter',
  SPACE: ' '
};

const isActionKeyPressed = e => Object.values(ACTION_KEYS).indexOf(e.key) > -1;

export const a11yClickHandler = (event, handler = false) => {
  try {
    if (!isActionKeyPressed(event)) {
      return;
    }

    if (handler) {
      handler(event);
    }
  } catch (e) {
    console.error(e);
  }
};

export const splitArray = arr => {
  const len = arr.length;
  const mid = len % 2 === 0 ? len / 2 : Math.floor(len / 2) + 1;
  return [arr.slice(0, mid), arr.slice(mid)];
};

export const isDarkTheme = theme => THEME_MAP.DARK === theme;

export const isLightTheme = theme => THEME_MAP.LIGHT === theme;

export const isGrayTheme = theme => THEME_MAP.GRAY === theme;

// TODO: we should add URL polyfill to SSR side and remove this function.
const removeHost = url => {
  const url_ = url.split('://');
  return url_[1] ? `/${url_[1].split('/').slice(1).join('/')}` : url;
};

export const isSamePath = (url, currentPath) => {
  if (!url || !currentPath) {
    return false;
  }

  const urlWithoutSearch = url.split('?')[0];
  const pathname = removeHost(urlWithoutSearch);

  return pathname.endsWith('/')
    ? pathname === (currentPath.endsWith('/') ? currentPath : `${currentPath}/`)
    : pathname === (currentPath.endsWith('/') ? currentPath.slice(0, -1) : currentPath);
};

// A/B Test: contactSalesButtonOnHeader
export const shouldRenderSignupButton = ({ hideSignupButton, contactSalesAbTestVariation }) => {
  const { CONTACT_SALES_AS_CTA } = CONTACT_SALES_AB_TEST_VARIATIONS;
  if (hideSignupButton) {
    return false;
  }
  if (CONTACT_SALES_AS_CTA === contactSalesAbTestVariation) {
    return false;
  }
  return true;
};
