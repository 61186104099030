import React from 'react';
import cx from 'classnames';
import {
  array,
  bool,
  string,
  func,
  oneOf,
  object,
  number
} from 'prop-types';

import { t } from '@jotforminc/translation';
import { GrowthAssetManager } from '@jotforminc/growth-asset-manager';
import { isEnterprise } from '@jotforminc/enterprise-utils';

import ArrowIcon from '../../assets/svg/arrow.svg';

import DefaultMenuType from '../MenuTypes/DefaultMenuType';
import TemplatesMenuType from '../MenuTypes/TemplatesMenuType';
import IntegrationsMenuType from '../MenuTypes/IntegrationsMenuType';
import ProductsMenuType from '../MenuTypes/ProductsMenuType';
import SupportMenuType from '../MenuTypes/SupportMenuType';
import SignMenuType from '../MenuTypes/SignMenuType';
import PdfEditorMenuType from '../MenuTypes/PdfEditorMenuType';

import { a11yClickHandler, isSamePath } from '../../utils';

import { MENU_TYPES } from '../../constants';

const menuTypes = {
  submenu: DefaultMenuType,
  templates: TemplatesMenuType,
  integrations: IntegrationsMenuType,
  products: ProductsMenuType,
  support: SupportMenuType,
  sign: SignMenuType,
  pdfEditor: PdfEditorMenuType
};

const WrapperComponent = ({
  // eslint-disable-next-line react/prop-types
  children, onClick, classNames, menuType, renderAs, target, newUrl, showRedDot
}) => (
  <a
    onClick={onClick}
    onKeyDown={event => a11yClickHandler(event, onClick)}
    className={classNames}
    tabIndex={0}
    {...(menuType && { 'aria-haspopup': 'listbox', role: 'button' })}
    {...(renderAs === 'link' && { href: newUrl(), target: target })}
  >
    {children}
    {showRedDot && <span className="jfRHeader--nav-menu-item-dot" />}
    {menuType && <ArrowIcon />}
  </a>
);

const NavLink = ({
  user,
  url,
  openedMenu,
  setOpenedMenu,
  target,
  title,
  linkClassName,
  menuType,
  renderAs,
  badge,
  icon,
  logHeaderAction,
  className,
  currentPath,
  isLoggedIn,
  isPricing,
  isMobileMenuActive,
  unreadSupportAnswerCount,
  showAiAgentBanner,
  ...menuProps
}) => {
  const MenuComponent = menuTypes[menuType];

  const showRedDot = isLoggedIn && unreadSupportAnswerCount > 0 && menuType === MENU_TYPES.SUPPORT && !isEnterprise();
  const isOpen = openedMenu === title;

  const defaultClassname = 'jfRHeader--nav-menu-item';
  const combinedClassname = cx(defaultClassname, {
    hasSubMenu: menuType,
    isBadge: badge,
    isPricing
  });
  const linkClassname = cx({
    isOpen,
    isCurrent: isSamePath(url, currentPath),
    [className]: className
  });

  const handleClick = () => {
    setTimeout(() => logHeaderAction({ action: 'headerMenu-click', target: title, project: 'jfHeaderCTA' }), 0);
    setOpenedMenu(prev => (prev === title ? '' : title));
  };

  const handleMenuClose = () => {
    setOpenedMenu('');
  };

  const newUrl = () => {
    if (isPricing && user?.campaign?.utm_campaign && user.campaign.utm_campaign !== 'NONE') {
      const utmCampaign = user.campaign.utm_campaign;
      const utmSource = `${user.campaign.utm_campaign}-pricing-nav`;
      const utmMedium = 'website';
      const utmContent = currentPath !== '/' ? currentPath.replace(/\//g, '') : 'homepage';
      return `${url}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}&utm_campaign=${utmCampaign}`;
    }
    return url;
  };

  return (
    <li
      className={combinedClassname}
      onMouseLeave={handleMenuClose}
    >
      <WrapperComponent
        onClick={handleClick}
        classNames={linkClassname}
        menuType={menuType}
        newUrl={newUrl}
        renderAs={renderAs}
        showRedDot={showRedDot}
        target={target}
      >
        {isPricing && (
          <GrowthAssetManager assetType="pricing-badge" />
        )}
        {icon && (
          <img
            src={icon}
            alt={title}
            className="jfRHeader--nav-menu-item-icon"
          />
        )}
        <span className='locale'>{t(title)}</span>
        {badge && (
          <strong className="jfRHeader--nav-menu-item-badge">
            {badge}
          </strong>
        )}
      </WrapperComponent>
      {menuType && (
      <MenuComponent
        {...menuProps}
        isOpen={isOpen}
        isLoggedIn={isLoggedIn}
        currentPath={currentPath}
        showAiAgentBanner={showAiAgentBanner}
        isMobileMenuActive={isMobileMenuActive}
        unreadSupportAnswerCount={unreadSupportAnswerCount}
      />
      )}
    </li>
  );
};

NavLink.propTypes = {
  user: object.isRequired,
  url: string,
  target: string,
  title: string,
  linkClassName: string,
  menuType: oneOf([
    MENU_TYPES.INTEGRATIONS,
    MENU_TYPES.PRODUCTS,
    MENU_TYPES.SUPPORT,
    MENU_TYPES.TEMPLATES,
    MENU_TYPES.SUBMENU,
    ''
  ]),
  menuItems: array,
  renderAs: oneOf(['link', 'button']),
  badge: string,
  icon: string,
  logHeaderAction: func,
  currentPath: string.isRequired,
  className: string,
  isLoggedIn: bool,
  isPricing: bool,
  isMobileMenuActive: bool,
  unreadSupportAnswerCount: number,
  openedMenu: string.isRequired,
  setOpenedMenu: func.isRequired,
  showAiAgentBanner: bool.isRequired
};

NavLink.defaultProps = {
  url: '',
  target: '_top',
  title: '',
  linkClassName: '',
  menuType: '',
  menuItems: [],
  renderAs: 'link',
  badge: '',
  icon: '',
  logHeaderAction: () => { },
  className: '',
  isLoggedIn: undefined,
  isPricing: false,
  isMobileMenuActive: false,
  unreadSupportAnswerCount: 0
};

export default NavLink;
