import React from 'react';
import { bool, string } from 'prop-types';
import { isDarkTheme, isGrayTheme, isLightTheme } from '../../utils';
import { logoPropsDefaultProps, logoPropsPropTypes, themePropType } from '../../propTypes';

// jotform logos
import LogoJotformDark from '../../assets/svg/logoJotformDark.svg';
import LogoJotformLight from '../../assets/svg/logoJotformLight.svg';
import LogoJotformFullLight from '../../assets/svg/logoJotformFullLight.svg';

// korean jotform logos
import LogoJotformKoreanDark from '../../assets/svg/logoJotformKoreanDark.svg';
import LogoJotformKoreanLight from '../../assets/svg/logoJotformKoreanLight.svg';
import LogoJotformKoreanFullLight from '../../assets/svg/logoJotformKoreanFullLight.svg';

const LogoVisual = ({
  theme,
  isKorean,
  className,
  logoProps: { logoFullLight }
}) => {
  const selectLogo = () => {
    if (logoFullLight) {
      return isKorean ? LogoJotformKoreanFullLight : LogoJotformFullLight;
    }

    if (isDarkTheme(theme)) {
      return isKorean ? LogoJotformKoreanLight : LogoJotformLight;
    }

    if (isLightTheme(theme) || isGrayTheme(theme)) {
      return isKorean ? LogoJotformKoreanDark : LogoJotformDark;
    }

    return null;
  };

  const SelectedLogo = selectLogo();

  return <SelectedLogo className={className} />;
};

LogoVisual.propTypes = {
  theme: themePropType.isRequired,
  className: string,
  isKorean: bool,
  logoProps: logoPropsPropTypes
};

LogoVisual.defaultProps = {
  className: '',
  isKorean: false,
  logoProps: logoPropsDefaultProps
};

export default LogoVisual;
