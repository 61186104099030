import React, { useState } from 'react';
import {
  array, bool, func, number, object, string
} from 'prop-types';

import { getUrlParameter } from '@jotforminc/utils';

import NavLink from '../NavLink/NavLink';

import productsJson from '../../menuOptions/products.json';

import {
  myFormsNavItem,
  enterpriseNavItem,
  templatesNavItem,
  integrationsNavItem,
  productsNavItem,
  supportNavItem,
  pricingsNavItem,
  signNavItem,
  pdfEditorNavItem
} from '../../menuOptions';
import { CONTACT_SALES_AB_TEST_VARIATIONS } from '../../constants';

const _defaultNavItems = [
  myFormsNavItem,
  templatesNavItem,
  integrationsNavItem,
  getUrlParameter('debug-json-menu') ? productsJson : productsNavItem,
  supportNavItem,
  enterpriseNavItem,
  pricingsNavItem
];

const NavItems = ({
  user,
  navItems,
  logHeaderAction,
  currentPath,
  isLoggedIn,
  defaultNavItems, // todo: ??
  isMobileMenuActive,
  unreadSupportAnswerCount,
  showAiAgentBanner,
  contactSalesAbTestVariation
}) => {
  const { CONTACT_SALES_AS_ITEM, NO_INTEGRATIONS_MENU } = CONTACT_SALES_AB_TEST_VARIATIONS;
  const [openedMenu, setOpenedMenu] = useState('');
  const selectNavItems = () => {
    if (navItems === false) return [];
    if (typeof navItems === 'undefined' || navItems?.length === 0) return _defaultNavItems;
    return navItems;
  };

  let items = [...selectNavItems()];

  // this is a temporary solution to add pdfEditor and sign to the navItems
  if (defaultNavItems.includes('pdfEditor')) {
    items.unshift(pdfEditorNavItem);
  }

  if (defaultNavItems.includes('sign')) {
    items.unshift(signNavItem);
  }

  // A/B Test: contactSalesButtonOnHeader
  if ([CONTACT_SALES_AS_ITEM, NO_INTEGRATIONS_MENU].includes(contactSalesAbTestVariation)) {
    items = items.filter(item => item.name !== 'integrations');
  }

  return (
    <>
      {items.map(({ name, linkProps, menuProps }) => (
        <NavLink
          key={name}
          {...linkProps}
          {...menuProps}
          user={user}
          isLoggedIn={isLoggedIn}
          openedMenu={openedMenu}
          setOpenedMenu={setOpenedMenu}
          currentPath={currentPath}
          isPricing={name === 'pricing'}
          logHeaderAction={logHeaderAction}
          showAiAgentBanner={showAiAgentBanner}
          isMobileMenuActive={isMobileMenuActive}
          unreadSupportAnswerCount={unreadSupportAnswerCount}
        />
      ))}
    </>
  );
};

NavItems.propTypes = {
  user: object.isRequired,
  navItems: array,
  logHeaderAction: func,
  currentPath: string.isRequired,
  isLoggedIn: bool,
  defaultNavItems: array,
  isMobileMenuActive: bool,
  unreadSupportAnswerCount: number,
  showAiAgentBanner: bool.isRequired,
  contactSalesAbTestVariation: string.isRequired
};

NavItems.defaultProps = {
  navItems: _defaultNavItems,
  logHeaderAction: () => {},
  isLoggedIn: undefined,
  defaultNavItems: [],
  isMobileMenuActive: false,
  unreadSupportAnswerCount: 0
};

export default NavItems;
