export const LOGIN_FLOW_APP_NAME = 'jfHeaderReact';

export const ACTIONS_PROJECT_NAME = 'megaMenuHeaderReact';

export const LOGIN_FLOW_ACTIONS_PROJECT_NAME = 'LoginFlowCommonHeader';

export const CUSTOMER_SUPPORT_ACTIONS_PROJECT_NAME = 'CustomerSupport';

export const MENU_BADGE_TYPES = {
  success: 'success',
  warning: 'warning',
  danger: 'danger'
};
