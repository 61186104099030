import { t } from '@jotforminc/translation';
import React, { useEffect } from 'react';
import {
  GA_SUFFIXES, createGaAttributes, saveCustomerAsHubspotFormByUsername, setEPActions,
  ASSET_IDENTIFIER, ALL_TEXTS, CTA_URLS, SDR_SOURCES
} from '@jotforminc/enterprise-promotions-utils';
import IconEnterprise from '../../../assets/svg/userGuideProfessionalServices/rocket.svg';
import '../../../styles/userGuideProfessionalServicesBanner.scss';

const UserGuideProfessionalServicesBanner = () => {
  const { PRODUCT: { USER_GUIDE_PROF_SERVICES_BANNER: target } } = ASSET_IDENTIFIER;
  const {
    USER_GUIDE_PROFESSIONAL_SERVICES: {
      CONTACT_SALES_URL,
      LEARN_MORE_URL
    }
  } = CTA_URLS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesGaq = createGaAttributes(target, GA_SUFFIXES.GET_A_QUOTE);
  const gaAttributesLm = createGaAttributes(target, GA_SUFFIXES.LEARN_MORE);

  useEffect(() => {
    // registerJotformAction({ action: 'seen', target });
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleGetAQuoteClick = () => {
    // registerJotformAction({ action: 'click', target: `getAQuoteButton-${target}` });
    setEPActions({ asset: target, target: 'getAQuoteButton', action: 'click' });
    saveCustomerAsHubspotFormByUsername({ lastProductAssetInteraction: SDR_SOURCES.USER_GUIDE_PROF_SERVICES_BANNER });
  };

  const handleLearnMoreClick = () => {
    // registerJotformAction({ action: 'click', target: `learnMoreButton-${target}` });
    setEPActions({ asset: target, target: 'learnMoreButton', action: 'click' });
    saveCustomerAsHubspotFormByUsername({ lastProductAssetInteraction: SDR_SOURCES.USER_GUIDE_PROF_SERVICES_BANNER });
  };

  return (
    <div
      {...gaAttributes}
      className='user-guide-professional-services'
      role="banner"
    >
      <div className='content-wrapper'>
        <h5 className="title">
          <IconEnterprise className="icon" />
          {t(ALL_TEXTS.GET_HANDS_ON_PROFESSIONAL_SERVICES)}
        </h5>
        <p className="description">{t(ALL_TEXTS.GET_HANDS_ON_PROFESSIONAL_SERVICES_DESCRIPTION)}</p>
      </div>
      <div className="button-wrapper">
        {/* get a quote */}
        <a
          {...gaAttributesGaq}
          target="_blank"
          className="contact"
          href={CONTACT_SALES_URL}
          onClick={handleGetAQuoteClick}
        >
          {t(ALL_TEXTS.GET_A_QUOTE)}
        </a>
        {/* learn more */}
        <a
          {...gaAttributesLm}
          target="_blank"
          className="learn-more"
          href={LEARN_MORE_URL}
          onClick={handleLearnMoreClick}
        >
          {t(ALL_TEXTS.LEARN_MORE)}
        </a>
      </div>
    </div>
  );
};

export default UserGuideProfessionalServicesBanner;
