import React, { useEffect, useState } from 'react';
import { setCookie } from '@jotforminc/utils';
import { bool, string, func } from 'prop-types';
import { t } from '@jotforminc/translation';
import {
  Button,
  Dropdown,
  DropdownOption
} from '@jotforminc/magnet';
import {
  IconLocationPinFilled,
  IconXmark
} from '@jotforminc/svg-icons';

const LanguageSwitcher = ({
  isSticky, languageSwitcher, setLanguageSwitcherVisible
}) => {
  const target = 'LanguageSwitcher';
  const [visible, setVisible] = useState(true);
  const {
    browserLanguage, switchText, languageCodeURLPaths, languagesAvailable
  } = languageSwitcher;

  const languageList = Array.from(document.querySelectorAll('link'))
    .filter(link => link.getAttribute('hreflang') && link.getAttribute('hreflang') !== 'x-default')
    .map(lang => lang.getAttribute('hreflang'));

  const languages = languageList.map(langCode => {
    // eslint-disable-next-line no-param-reassign
    langCode = langCode === 'pt-br' ? 'pt' : langCode;
    let [longLangKey] = Object.entries(languageCodeURLPaths).find(path => path[1] === langCode);

    longLangKey = longLangKey === 'pt-BR' ? 'pt-PT' : longLangKey;
    const [langPrettyName] = Object.entries(languagesAvailable).find(lang => lang[1] === longLangKey);

    return { label: langPrettyName, value: longLangKey, order: Object.keys(languagesAvailable).findIndex(lang => lang === langPrettyName) };
  }).sort((a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  });

  const [selectedLang] = useState(browserLanguage);
  const prettyBrowserLang = languages.find(lang => lang.value === selectedLang)?.label || '';

  const handleClose = () => {
    setCookie('LANGUAGE_SWITCHER:Flyin', 'true', 365);
    global.window.logAbTestActionLangSwitcher({ action: 'close', target });
    document.querySelector('body').classList.remove('jf-languageSwitcher');
    setLanguageSwitcherVisible(false);
  };

  useEffect(() => {
    global.window.logAbTestActionLangSwitcher({ action: 'seen', target });
  }, []);

  useEffect(() => {
    if (!isSticky) {
      document.querySelector('body').classList.add('jf-languageSwitcher--isSticky');
      setVisible(true);
    } else {
      document.querySelector('body').classList.remove('jf-languageSwitcher--isSticky');
      setVisible(false);
    }
  }, [isSticky]);

  useEffect(() => {
    if (visible) {
      document.querySelector('body').classList.add('jf-languageSwitcher');
    } else {
      document.querySelector('body').classList.remove('jf-languageSwitcher');
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  const splitedSwithText = switchText.split('{languageSwitcher}');
  return (
    <section
      className="jfRHeader--language-switcher"
    >
      <div className='jfRHeader--language-switcher-wrapper'>
        <div className='jfRHeader--language-switcher-textContainer'>
          <IconLocationPinFilled className='jfRHeader--language-switcher-icon-pin' />
          <span>
            {
              splitedSwithText.map((text, index) => {
                if (index === 1) {
                  return (
                    <div className='jfRHeader--language-dropdown-wrapper'>
                      <Dropdown
                        className="jfRHeader--language-dropdown"
                        size="small"
                        searchable={false}
                        value={selectedLang}
                        onChange={newLang => {
                          if (typeof window.changeLanguage === 'function') {
                            const hreflang = languageList.find(lang => lang.includes(newLang.split('-')[0]));
                            global.window.logAbTestActionLangSwitcher({ action: 'switch-lang', target: newLang });
                            window.changeLanguage(newLang, hreflang);
                          }
                        }}
                      >
                        {
                          languages.map(language => (
                            <DropdownOption
                              value={language.value}
                            >
                              {language.label}
                            </DropdownOption>
                          ))
                        }
                      </Dropdown>
                      <span>?</span>
                    </div>
                  );
                }
                return text;
              })
            }
          </span>
        </div>
        <Button
          size="small"
          onClick={() => {
            if (typeof window.changeLanguage === 'function') {
              const hreflang = languageList.find(lang => lang.includes(selectedLang.split('-')[0]));
              global.window.logAbTestActionLangSwitcher({ action: 'switch-lang', target: selectedLang });
              window.changeLanguage(selectedLang, hreflang);
            }
          }}
        >
          <span className='hidden md:block'>{t('Switch to {language}'.replace('{language}', prettyBrowserLang))}</span>
          <span className='block md:hidden'>{t('Switch')}</span>
        </Button>
        <Button
          colorStyle="neutral"
          rounded
          showTextOnHover
          className="jfRHeader--language-switcher-close"
          startIcon={IconXmark}
          variant="ghost"
          onClick={handleClose}
        />
      </div>
    </section>
  );
};

LanguageSwitcher.propTypes = {
  isSticky: bool,
  languageSwitcher: string,
  setLanguageSwitcherVisible: func
};

LanguageSwitcher.defaultProps = {
  isSticky: false,
  languageSwitcher: { show: false },
  setLanguageSwitcherVisible: f => f
};

export default LanguageSwitcher;
