import React from 'react';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { array, bool, string } from 'prop-types';
import { IconChevronRight } from '@jotforminc/svg-icons';

const ProductsMenuType = ({
  menuItems, sideMenuItems, menuTitle, sideMenuTitle, sideMenuSeeMoreTitle, sideMenuSeeMoreUrl, isOpen, isLoggedIn
}) => {
  const ProductsMenuTypeClasses = cx('jfRHeader--nav-sub-menu jfProducts', {
    isOpen
  });
  return (
    <ul className={ProductsMenuTypeClasses}>
      {menuItems.length > 0 && (
        <li className="jfSub-menu">
          <ul>
            <li className="jfRHeader--nav-sub-menu-text">{t(menuTitle)}</li>
            {menuItems.map(({
              name, title, url, urlGuest, icon
            }) => {
              const _url = !isLoggedIn && urlGuest ? urlGuest : url;
              return (
                <li key={name} className={`jfRHeader--nav-sub-menu-item${name === 'jotformSalesforce' ? ' jfRHeader--nav-sub-menu-item-salesforce' : ''}`}>
                  <a href={_url} className="jfRHeader--nav-sub-menu-item-link">
                    {icon && <img src={icon} alt={t(title)} className="jfRHeader--nav-sub-menu-item-icon" />}
                    {t(title)}
                    {name === 'jotformSalesforce' && <span className="discover-now">{t('Discover Now')}</span>}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      )}
      {sideMenuItems.length > 0 && (
        <li className="jfSub-menu jfFeatures">
          <ul>
            <li className="jfRHeader--nav-sub-menu-text">{t(sideMenuTitle)}</li>
            {sideMenuItems.map(({
              name, title, url, icon
            }) => (
              <li key={name} className='jfRHeader--nav-sub-menu-item'>
                <a href={url}>
                  {icon && <img src={icon} alt={t(title)} className="jfRHeader--nav-sub-menu-item-icon" />}
                  {t(title)}
                </a>
              </li>
            ))}
            <li className='jfRHeader--nav-sub-menu-item'>
              <a href={sideMenuSeeMoreUrl} className="jfRHeader--nav-sub-menu-item-link moreLink">
                <span className="locale">{t(sideMenuSeeMoreTitle)}</span>
                <IconChevronRight />
              </a>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
};

ProductsMenuType.propTypes = {
  menuItems: array.isRequired,
  sideMenuItems: array.isRequired,
  menuTitle: string.isRequired,
  sideMenuTitle: string.isRequired,
  sideMenuSeeMoreTitle: string.isRequired,
  sideMenuSeeMoreUrl: string.isRequired,
  isOpen: bool.isRequired,
  isLoggedIn: bool.isRequired
};

export default ProductsMenuType;
