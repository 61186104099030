const crmLinks = [
  {
    name: 'salesforce',
    title: 'Salesforce',
    url: '/integrations/salesforce',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/salesforce.svg'
  },
  {
    name: 'hubspot',
    title: 'Hubspot',
    url: '/integrations/hubspot',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/hubspot.svg'
  },
  {
    name: 'zohoCrm',
    title: 'Zoho CRM',
    url: '/integrations/zoho-crm',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/zoho.svg'
  },
  {
    name: 'activeCampaign',
    title: 'Active Campaign',
    url: '/integrations/activeCampaign',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/active-campaign.svg'
  },
  {
    name: 'pipedrive',
    title: 'Pipedrive',
    url: '/integrations/pipedrive',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/pipedrive.svg'
  },
  {
    name: 'zendek',
    title: 'Zendesk',
    url: '/integrations/zendesk',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/zendesk.svg'
  },
  {
    name: 'insightly',
    title: 'Insightly',
    url: '/integrations/insightly',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/insightly.svg'
  },
  {
    name: 'keap',
    title: 'Keap',
    url: '/integrations/keap',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/keap.svg'
  },
  {
    name: 'engageBay',
    title: 'EngageBay',
    url: '/integrations/engageBay',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/engagebay.svg'
  }
];

export default crmLinks;
