import { useEffect, useState } from 'react';

const useLazyLoginGroup = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const handleMouseMove = () => {
      setLoad(true);
      window.removeEventListener('mousemove', handleMouseMove);
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return [load];
};

export default useLazyLoginGroup;
