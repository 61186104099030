import React, { useEffect } from 'react';
import { t } from '@jotforminc/translation';
import { arrayOf, object, string } from 'prop-types';
import '../../../styles/blogDownloadBanner.scss';
import {
  ASSET_IDENTIFIER, ALL_TEXTS, userPropType, renderModal, setEPActions, createGaAttributes, GA_SUFFIXES
} from '@jotforminc/enterprise-promotions-utils';
import { IconCheck, LogoJotformColor } from '@jotforminc/svg-icons';
import { PdfDownloadModal } from '../../Modals';
import ReportVisual from '../../../assets/svg/blogDownloadBanner/visual.svg';

const BlogDownloadBanner = ({
  user, badgeText, bannerTitle, bannerDescription, bannerVisualUrl, bannerItems, modalProps, pdfUrl
}) => {
  const { PRODUCT: { PDF_DOWNLOAD_BANNER: target } } = ASSET_IDENTIFIER;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesGetPdf = createGaAttributes(target, GA_SUFFIXES.GET_PDF);

  useEffect(() => {
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleClick = () => {
    setEPActions({ asset: target, target: 'getPdfButton', action: 'click' });

    renderModal({
      user,
      modalProps,
      pdfUrl,
      Modal: PdfDownloadModal
    });
  };

  return (
    <div
      {...gaAttributes} className='blog-download-banner' onClick={handleClick}
      role='button'
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
    >
      <div className='left-col'>
        <span className='badge'>{badgeText}</span>
        <span className='title-wrapper'>
          <span className='title'>
            {bannerTitle}
            :
            {' '}
          </span>

          <span className='description'>
            {bannerDescription}
          </span>
        </span>
        <ul className='list-items'>
          {bannerItems.map(item => (
            <li>
              <IconCheck className='icon' />
              <span>{item}</span>
            </li>
          )
          )}
          <li>
            <IconCheck className='icon' />
            <span>And more</span>
          </li>
        </ul>
      </div>
      <div className='right-col'>
        <div className='logo-wrapper'>
          <LogoJotformColor className='logo' />
          <span className='enterprise'>{t(ALL_TEXTS.ENTERPRISE_CAPITALIZED)}</span>
        </div>

        <div className='visual-area'>
          <img
            className='visual'
            src={bannerVisualUrl}
            alt={bannerTitle}
          />
          <ReportVisual className='background-visual' />
        </div>

        <button
          {...gaAttributesGetPdf}
          type='button'
          className='main-cta'
        >
          {t(ALL_TEXTS.GET_PDF)}
        </button>
      </div>
    </div>

  );
};

BlogDownloadBanner.propTypes = {
  user: userPropType,
  badgeText: string.isRequired,
  bannerTitle: string.isRequired,
  bannerDescription: string.isRequired,
  bannerVisualUrl: string.isRequired,
  pdfUrl: string.isRequired,
  bannerItems: arrayOf(string).isRequired,
  modalProps: object.isRequired
};

BlogDownloadBanner.defaultProps = {
  user: {}
};

export default BlogDownloadBanner;
