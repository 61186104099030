// What is SDR? Sales Development Representative
// Information of the users that show intent to Jotform Enterprise Product are being added to Hubspot.
// Our agents (SDR) get in touch with the users that have record on Hubspot.
export const SDR_SOURCES = {
  ACCOUNT_BOX_ADMIN_CONSOLE_BUTTON: 'AdminConsole',
  ACCOUNT_BOX_ENTERPRISE_PROMO: 'AccountBoxEnterprisePromotion',
  CONTACT_PAGE_DEDICATED_SUPPORT: 'ContactPageDedicatedSupport',
  ENTERPRISE_GOVERNMENT_MODAL: 'EnterpriseGovernmentModal',
  ENTERPRISE_HEALTHCARE_REPORT_BANNER: 'EnterpriseHealthcareReportBanner',
  FORM_BUILDER_ADD_COLLABORATORS_BANNER: 'AddCollaborators',
  FORM_BUILDER_HELP_MENU: 'FormBuilderHelpMenu',
  FORM_BUILDER_SSO_PREFILL: 'SsoPrefill',
  FORM_PROPERTIES_EDITOR_SSO: 'SsoToggle',
  GENERAL_FAQ_PROF_SERVICES_BANNER: 'GeneralFaqProfessionalServicesBanner',
  INBOX_PROFESSIONAL_SOLUTIONS_BANNER: 'InboxProfessionalSolutionsBanner',
  MILESTONE_HUBSPOT_MODAL: 'MilestoneHubspotModal',
  MY_ACCOUNT_CUSTOM_DOMAIN_API_BANNER: 'MyAccountCustomDomainApiBanner',
  MY_ACCOUNT_DEDICATED_SERVER: 'DedicatedServer',
  MY_ACCOUNT_TEAMS_SETTINGS_CUSTOM_URL: 'TeamCustomURL',
  MY_ACCOUNT_SESSIONS_ACCOUNT_SHARING_BANNER: 'SessionsAccountSharingBanner',
  MY_ACCOUNT_SESSIONS_ACCOUNT_SHARING_BANNER_CS_CLICK: 'SessionsAccountSharingBannerCsClick',
  MY_ACCOUNT_WHITE_LABEL: 'WhiteLabel',
  APPROVAL_WHITE_LABEL: 'ApprovalWhiteLabel',
  MY_FORMS_AU_DATA_RESIDENCY_MODAL: 'MyFormsAuDataResidencyModal',
  MY_FORMS_HK_DATA_RESIDENCY_MODAL: 'MyFormsHkDataResidencyModal',
  MY_FORMS_SG_DATA_RESIDENCY_MODAL: 'MyFormsSgDataResidencyModal',
  MY_FORMS_SHARE_FOLDER: 'ShareFolder',
  PROFESSIONAL_SERVICES_CONTACT_BANNER: 'ProfessionalServicesContactBanner',
  SHARE_PANEL_ASSIGN_TO_EVERYONE: 'AssignToggle',
  SHARE_PANEL_SETTINGS_SSO_BANNER: 'SsoBanner',
  SHARE_PANEL_SHARE_LINK: 'CustomDomain',
  TEAMS_ADD_NEW_USER: 'TeamInvite',
  USER_GUIDE_PROF_SERVICES_BANNER: 'UserGuideProfessionalServicesBanner',
  WORKFLOW_GROUP_APPROVAL_BANNER: 'WorkflowGroupApprovalBanner',
  ENTERPRISE_BEGINNERS_BOOK_MODAL: 'EnterpriseBookDownload',
  SUPPORT_MENU: 'HeaderSupportMenu',
  PAGE_VIEW_PRICING_ENTERPRISE: 'pageview_pricing/enterprise'
};
