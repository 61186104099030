const paymentLinks = [
  {
    name: 'paypalPersonal',
    title: 'Paypal Personal',
    url: '/integrations/paypal-personal',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paypal.svg'
  },
  {
    name: 'square',
    title: 'Square',
    url: '/integrations/square',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/square.svg'
  },
  {
    name: 'paypalBusiness',
    title: 'Paypal Business',
    url: '/integrations/paypal-business',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paypal.svg'
  },
  {
    name: 'stripe',
    title: 'Stripe',
    url: '/integrations/stripe',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/stripe.svg'
  },
  {
    name: 'authorizeNet',
    title: 'Authorize.Net',
    alt: 'Blue and Yellow Circle for AuthorizeNet',
    url: '/integrations/authorize-net',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/authorize-net-v2.svg'
  },
  {
    name: 'applePayandGooglePay',
    title: 'Apple Pay & Google Pay',
    url: '/integrations/apple-pay-google-pay',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/apple-pay-google-pay.svg'
  },
  {
    name: 'paysafe',
    title: 'Paysafe',
    url: '/integrations/paysafe',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paysafe.svg'
  },
  {
    name: 'mollie',
    title: 'Mollie',
    url: '/integrations/mollie',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/mollie.svg'
  },
  {
    name: 'venmo',
    title: 'Venmo',
    url: '/integrations/venmo',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/venmo.svg'
  }
];

export default paymentLinks;
