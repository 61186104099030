// eslint-disable-next-line import/no-unresolved
import '@jotforminc/router-bridge/init';
import { Header } from '@jotforminc/header';

import React from 'react';
import { render, hydrate } from 'react-dom';

const jfRHeaderShadowHost = document.querySelector('#jf-common-header-host');
const jfRHeaderDocument = jfRHeaderShadowHost?.shadowRoot || document;
const jfRHeaderEl = jfRHeaderDocument ? jfRHeaderDocument.querySelector('#jfHeaderBodyWrapper') : undefined;
const rootEl = document.getElementById('root');

const renderer = jfRHeaderEl ? hydrate : render;

renderer(
  <Header />,
  jfRHeaderEl || rootEl
);
