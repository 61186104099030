import React from 'react';
import cx from 'classnames';
import { bool, func, string } from 'prop-types';
import { handleCustomNavigation } from '@jotforminc/utils';

import LogoVisual from './LogoVisual';
import { logoPropsDefaultProps, logoPropsPropTypes, themePropType } from '../../propTypes';
import { THEME_MAP } from '../../constants';

const Logo = ({
  theme,
  isSticky,
  language,
  logHeaderAction,
  isMobileMenuActive,
  isEnterprise,
  logoProps: {
    logoUrl,
    logoFullLight,
    customLogoAsReactElement,
    customLogoAsHtmlString: customLogoAsHtmlStringProp,
    customDarkLogoAsHtmlString,
    enterpriseLogoSrc
  }
}) => {
  const customLogoAsHtmlString = theme === THEME_MAP.DARK && customDarkLogoAsHtmlString ? customDarkLogoAsHtmlString : customLogoAsHtmlStringProp;
  const isCustomLogo = customLogoAsReactElement || customLogoAsHtmlString;
  const isKorean = language === 'ko-KR';
  const logoSvgClasses = cx({ isSticky });
  const url = logoUrl || '/';

  const handleClick = e => {
    e.preventDefault();

    if (isEnterprise) {
      logHeaderAction({ action: 'click', target: 'topLeftMainLogo' });
    }

    handleCustomNavigation(url, '_self', true);
  };

  if (!isCustomLogo || (isCustomLogo && isKorean)) {
    return (
      <a
        href={url}
        tabIndex={0}
        id="js-logoMarkup"
        aria-label="Jotform Logo"
        className='jfRHeader--logo-link'
        onClick={handleClick}
      >
        {!isEnterprise && (
          <LogoVisual
            theme={isMobileMenuActive ? THEME_MAP.LIGHT : theme}
            isKorean={isKorean}
            className={logoSvgClasses}
            logoProps={{ logoFullLight }}
          />
        )}
        {isEnterprise && enterpriseLogoSrc && <img src={enterpriseLogoSrc} alt='company logo' />}
      </a>
    );
  }

  return (
    <>
      {customLogoAsReactElement && (
        <a
          href={url}
          tabIndex={0}
          className='jfRHeader--logo-link'
          aria-label="Jotform Logo"
          onClick={handleClick}
        >
          {customLogoAsReactElement}
        </a>
      )}
      {customLogoAsHtmlString && (
        <a
          tabIndex={0}
          href={url}
          aria-label="Jotform Logo"
          className='jfRHeader--logo-link'
          dangerouslySetInnerHTML={{ __html: customLogoAsHtmlString }}
          onClick={handleClick}
        />
      )}
    </>
  );
};

Logo.propTypes = {
  theme: themePropType.isRequired,
  isSticky: bool,
  language: string,
  logoProps: logoPropsPropTypes,
  logHeaderAction: func,
  isMobileMenuActive: bool,
  isEnterprise: bool.isRequired
};

Logo.defaultProps = {
  isSticky: false,
  language: undefined,
  logoProps: logoPropsDefaultProps,
  logHeaderAction: f => f,
  isMobileMenuActive: false
};

export default Logo;

// make sure you test your changings on these Pages
// https://www.jotform.com/myforms/
// https://www.jotform.com/form-templates/
// https://www.jotform.com/blog/
// https://www.jotform.com/terms/
// https://www.jotform.com/return-to-office-guidelines/
// https://www.jotform.com/data-filtering/
// https://www.jotform.com/photography/
// https://www.jotform.com/enterprise/
// https://www.jotform.com/online-payments/
// https://www.jotform.com/2checkout/
// https://www.jotform.com/products/sign/
// https://www.jotform.com/blog/announcing-jotform-group-approvals/
// https://www.jotform.com/wufoo-alternative/
