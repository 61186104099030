const getJotFormEnv = () => window.__jfrouter?.JOTFORM_ENV || window.JOTFORM_ENV;
const getUserAccountType = user => user?.account_type?.name || user?.accountType?.name || user?.account_type || user?.accountType;

export const isGuest = user => getUserAccountType(user) === 'GUEST';
export const isAdmin = user => getUserAccountType(user) === 'ADMIN';
export const isSupport = user => getUserAccountType(user) === 'SUPPORT';
export const isBSG = user => ['BRONZE', 'SILVER', 'GOLD'].includes(getUserAccountType(user));

export const isTesting = () => getJotFormEnv() === 'TESTING';
export const isEnterprise = () => getJotFormEnv() === 'ENTERPRISE';
export const isProduction = () => getJotFormEnv() === 'PRODUCTION';
export const isDevelopment = () => getJotFormEnv() === 'DEVELOPMENT';

// modify footer addres, kvkk, ccpa, terms based on user location
export const runFooterCustomFunction = user => {
  if (!user) return;
  if (global.window.footerFunction && typeof global.window.footerCustomFunction === 'function') {
    global.window.footerCustomFunction(user);
  }
};

// keep this for now for backwards compatibility
export const exceptionallyOverwriteUserDataForFormUser = (user, isFormUser) => {
  if (!isFormUser) return;
  const usr = user;
  usr.username = user.email; // Form users don't have a username
  usr.usage = {};
  usr.account_type = { limits: {}, name: 'FORM_USER' };
};

export const isAiAgentBannerAvailable = user => {
  const isEnabled = true;
  const urlParam = new URLSearchParams(window.location.search).get('aiab') === '1';
  if (urlParam) return true;
  if (!isEnabled || isEnterprise()) return false;
  if (isAdmin(user) || isSupport(user)) return true;
  const isAiAgentExistingUserAB = user?.aiAgentExistingUserAB === '26421';
  const isAiAgentNewUserAB = user?.aiAgentNewUserAB === '26441';
  const isAiAgentBetaUser = user?.aiAgentBetaUser === '1';
  return isAiAgentExistingUserAB || isAiAgentNewUserAB || isAiAgentBetaUser;
};
