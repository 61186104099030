import React from 'react';
import cx from 'classnames';
import { bool } from 'prop-types';
import { t } from '@jotforminc/translation';

import { TEXTS } from '../../constants';
import GovernmentSvg from '../../assets/svg/government.svg';
import { isDarkTheme } from '../../utils';
import { themePropType } from '../../propTypes';

const BadgeGovernment = ({
  theme,
  isMobileMenuActive
}) => {
  return (
    <div className={
          cx('flex border-l', {
            'border-l-white border-l-opacity-10': isDarkTheme(theme) && !isMobileMenuActive,
            'border-l-gray-25 border-l-opacity-30': !isDarkTheme(theme) || isMobileMenuActive
          })
        }
    >
      <span className="w-7 h-auto flex items-center ml-2 xl:ml-4">
        <GovernmentSvg className="w-full h-auto" />
      </span>
      <span className={
            cx('locale w-24 ml-2 h-auto text-sm font-medium', {
              'color-white': isDarkTheme(theme) && !isMobileMenuActive,
              'color-brand-blue': !isDarkTheme(theme) || isMobileMenuActive
            })
          }
      >
        {t(TEXTS.GOVERNMENT)}
      </span>
    </div>
  );
};

BadgeGovernment.propTypes = {
  theme: themePropType.isRequired,
  isMobileMenuActive: bool
};

BadgeGovernment.defaultProps = {
  isMobileMenuActive: false
};

export default BadgeGovernment;
