import { useEffect, useState } from 'react';
import { getHeaderRootEl } from '../utils';

const useStickyHeader = (
  activateBehaviour = false,
  scrollTreshold = 0
) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const rootEl = document.querySelector('html');
    const bodyEl = document.querySelector('body');
    const headerEl = getHeaderRootEl().querySelector('.jfRHeader');
    const CLASS_NAME = 'jfHeader-sticky-on'; // use this className for backwards compatibility

    const handleScroll = () => {
      if (!activateBehaviour) return;
      const isTresholdExceeded = rootEl?.scrollTop > scrollTreshold;
      setIsSticky(isTresholdExceeded);

      if (isTresholdExceeded) {
        bodyEl.style.paddingTop = `${headerEl.clientHeight}px`;
        rootEl?.classList.add(CLASS_NAME);
      } else {
        bodyEl.style.paddingTop = '0px';
        rootEl?.classList.remove(CLASS_NAME);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [isSticky];
};

export default useStickyHeader;
