import React, { useEffect } from 'react';
import { func, string } from 'prop-types';
import {
  ASSET_IDENTIFIER, GA_FORM_TRACKING_ITEMS, userPropType,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, mapCallbacksToGaTrackingItems, setEPActions, setEpUserSettings
} from '@jotforminc/enterprise-promotions-utils';
import AdvertisementLeadFlowModal from './AdvertisementLeadFlowModal';

const AdSecondExitIntentModal = ({
  logAbTestAction, seenAtKey, onClose, ...props
}) => {
  const { AB_TEST: { AD_SECOND_EXIT_INTENT_MODAL: target } } = ASSET_IDENTIFIER;
  let { [target]: gaFormTrackingItems } = GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    setEpUserSettings(seenAtKey);
  }, []);

  const handleSeen = () => {
    const actionData = { action: 'seen', target };

    logAbTestAction(actionData);
    setEPActions({ asset: target, ...actionData });
  };

  const handleCloseClick = () => {
    logAbTestAction({ action: 'click', target: `closeButton-${target}` });
    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);

    const actionData = { action: 'close', target };

    logAbTestAction(actionData);
    setEPActions({ asset: target, ...actionData });

    onClose();
  };

  const handleSendRequest = () => {
    logAbTestAction({ action: 'click', target: `sendRequestButton-${target}` });
    setEPActions({ asset: target, target: 'sendRequestButton', action: 'click' });
  };

  const handleFormSubmit = () => {
    const actionData = { action: 'formSubmit', target };

    logAbTestAction(actionData);
    setEPActions({ asset: target, ...actionData });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleSendRequest]);

  return (
    <AdvertisementLeadFlowModal
      {...props}
      gaAttributes={gaAttributes}
      gaAttributesClose={gaAttributesClose}
      gaFormTrackingItems={gaFormTrackingItems}
      onSeen={handleSeen}
      onClose={handleClose}
      onCloseClick={handleCloseClick}
      onFormSubmit={handleFormSubmit}
    />
  );
};

AdSecondExitIntentModal.propTypes = {
  user: userPropType.isRequired,
  onSeen: func,
  onClose: func,
  logAbTestAction: func,
  seenAtKey: string.isRequired
};

AdSecondExitIntentModal.defaultProps = {
  onSeen: f => f,
  onClose: f => f,
  logAbTestAction: f => f
};

export default AdSecondExitIntentModal;
