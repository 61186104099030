import {
  loadFacebookJS, loadGoogleJS, loadLoginFlow, loadMicrosotfJS
} from './loadLoginFlow';
import { openSocialLoginFlow } from './openSocialLoginFlow';
import { toggleSignupLoaders } from './toggleSignupLoaders';

export const attachLoginFlowToTrioButtons = () => {
  const socialButtons = document.querySelectorAll('button.google, button.facebook, button.microsoft');

  socialButtons.forEach(button => {
    button.addEventListener('click', async event => {
      let socialMedia = '';
      const classNames = event.currentTarget.className;

      if (classNames.includes('google')) socialMedia = 'google';
      if (classNames.includes('facebook')) socialMedia = 'facebook';
      if (classNames.includes('microsoft')) socialMedia = 'microsoft';

      if (!window.loginFlowHelper) {
        await Promise.all([
          loadLoginFlow(),
          loadFacebookJS(),
          loadGoogleJS(),
          loadMicrosotfJS()
        ]);

        if (socialMedia !== 'google') toggleSignupLoaders('show');
        openSocialLoginFlow(socialMedia);
        return;
      }

      if (socialMedia !== 'google') toggleSignupLoaders('show');
      openSocialLoginFlow(socialMedia);
    });
  });
};
