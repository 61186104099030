import React from 'react';
import { func, node } from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconXmark } from '@jotforminc/svg-icons';
import { ALL_TEXTS } from '@jotforminc/enterprise-promotions-utils';

export const DialogRenderer = ({ children }) => (<div className="jfBasicModal healthcare-report-modal">{children}</div>);

DialogRenderer.propTypes = { children: node };
DialogRenderer.defaultProps = { children: null };

export const ContentRenderer = ({ children, ...props }) => (
  <div {...props} className="healthcare-report-modal-content">
    {children}
  </div>
);

ContentRenderer.propTypes = { children: node };
ContentRenderer.defaultProps = { children: null };

export const HeaderRenderer = ({ onCloseClick, ...props }) => (
  <>
    <button
      {...props}
      type="button"
      aria-label={t(ALL_TEXTS.CLOSE_MODAL)}
      className="healthcare-report-modal-close size-32"
      onClick={onCloseClick}
    >
      <IconXmark />
    </button>
  </>
);

HeaderRenderer.propTypes = { onCloseClick: func };
HeaderRenderer.defaultProps = { onCloseClick: f => f };
