/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React, { Fragment, useEffect, useState } from 'react';
import { StyleSheetManager } from 'styled-components';
import cx from 'classnames';
import {
  string, bool, array, elementType, func, node, object
} from 'prop-types';
import {
  logoPropsDefaultProps, logoPropsPropTypes, logoSideLinkPropsDefaultProps, logoSideLinkPropsPropTypes,
  themePropType
} from '../../propTypes';
import { CONTACT_SALES_AB_TEST_VARIATIONS, THEME_CLASSNAME_MAP } from '../../constants';
import { isDarkTheme, isGrayTheme } from '../../utils';
import HamburgerButton from '../Navigation/HamburgerButton';
import Navigation from '../Navigation/Navigation';
import NavItems from '../Navigation/NavItems';
import SignupButtonMobile from '../Navigation/SignupButtonMobile';
import { LogoGroup } from '../LogoGroup';
import {
  ScHeaderWrapper, ScHeader, ScHeaderContainer, ScLogoWrapper
} from '../Sc';
import ContactSalesMobile from '../Navigation/ContactSalesMobile';

const HeaderBody = ({
  AppPickerRenderer,
  LoginGroupRenderer,
  children,
  customNavItems,
  logoProps,
  logoSideLinkProps,
  disableLoginFlow,
  hideLoginButton,
  hideSignupButton,
  forceRTL,
  bgColor,
  fullWidth,
  isMobileScreen,
  isMobileMenuActive,
  isSticky,
  onMobileMenuButtonClick,
  onMobileSignupButtonClick,
  user,
  theme,
  logHeaderAction,
  currentPath,
  isLoggedIn,
  replaceAuthButtons,
  language,
  defaultNavItems,
  menuStyles,
  isEnterprise,
  showAiAgentBanner,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL
}) => {
  const [bgStyles, setBgStyles] = useState({});
  const outlineWrapper = cx('jfRHeader', {
    'jfRHeader--dark-mode': (isDarkTheme(theme) && !isMobileScreen) || (isDarkTheme(theme) && isMobileScreen && !isMobileMenuActive),
    'jfRHeader--gray-mode': (isGrayTheme(theme) && !isMobileScreen) || (isGrayTheme(theme) && isMobileScreen && !isMobileMenuActive),
    isSticky: isSticky && !isMobileMenuActive,
    'jfRHeader--full-width': fullWidth,
    [THEME_CLASSNAME_MAP[theme]]: !!THEME_CLASSNAME_MAP[theme], // hasOwn would be better but im not sure if that works on ie11
    isMobileMenuActive
  });

  const { CONTACT_SALES_AS_CTA } = CONTACT_SALES_AB_TEST_VARIATIONS;

  const outlineClasses = cx('jfRHeader--container', {
    fullWidth,
    mobileScreen: isMobileScreen && isMobileMenuActive,
    isMobileMenuActive,
    isEnterprise
  });

  // TODO: It'll remove bgColor prop.
  useEffect(() => {
    if (!(menuStyles?.headerBackground || menuStyles?.headerBackgroundSticky)) {
      if (bgColor && isSticky) {
        setBgStyles({ '--jf-header-bg-sticky': bgColor });
      } else if (bgColor) {
        setBgStyles({ '--jf-header-bg': bgColor });
      }
    }
  }, [bgColor, isSticky, menuStyles]);

  const unreadSupportAnswerCount = parseInt(user?.unreadSupportAnswerCount || 0, 10);

  return (
    <StyleSheetManager target={global.window?.document?.querySelector('#jf-common-header-host')?.shadowRoot}>
      <ScHeaderWrapper menuStyles={menuStyles}>
        {children}
        <ScHeader
          className={outlineWrapper}
          menuStyles={menuStyles}
          style={bgStyles}
          dir={cx({ rtl: forceRTL })}
        >
          <ScHeaderContainer className={outlineClasses} id="headerWrapper">
            <ScLogoWrapper>
              {/* logo */}
              <LogoGroup
                user={user}
                theme={theme}
                isSticky={isSticky}
                language={language}
                isMobileMenuActive={isMobileMenuActive}
                logoProps={logoProps}
                logoSideLinkProps={logoSideLinkProps}
                logHeaderAction={logHeaderAction}
                isEnterprise={isEnterprise}
                currentPath={currentPath}
              />
              {/* app picker */}
              <AppPickerRenderer />
              {/* mobile only */}
              <div className="jfRHeader--mobile-nav">
                {(!disableLoginFlow && !hideSignupButton && !isLoggedIn && !replaceAuthButtons && contactSalesAbTestVariation !== CONTACT_SALES_AS_CTA) && (
                  <SignupButtonMobile
                    onClick={onMobileSignupButtonClick}
                  />
                )}
                {/* A/B Test: contactSalesButtonOnHeader */}
                {(!disableLoginFlow && !hideSignupButton && !isLoggedIn && !replaceAuthButtons && contactSalesAbTestVariation === CONTACT_SALES_AS_CTA) && (
                  <ContactSalesMobile
                    contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
                  />
                )}
                {((customNavItems !== false) || !disableLoginFlow) && (
                  <HamburgerButton
                    isActive={isMobileMenuActive}
                    onClick={onMobileMenuButtonClick}
                  />
                )}
              </div>
            </ScLogoWrapper>
            <Navigation
              isLoggedIn={isLoggedIn}
              username={user?.username}
              avatarUrl={user?.avatarUrl}
              isMobile={isMobileMenuActive}
              disableLoginFlow={disableLoginFlow}
              hideLoginButton={hideLoginButton}
              hideSignupButton={hideSignupButton}
              LoginGroupRenderer={LoginGroupRenderer}
              replaceAuthButtons={replaceAuthButtons}
              contactSalesAbTestVariation={contactSalesAbTestVariation}
              contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
            >
              <NavItems
                user={user}
                isLoggedIn={isLoggedIn}
                currentPath={currentPath}
                navItems={customNavItems}
                logHeaderAction={logHeaderAction}
                defaultNavItems={defaultNavItems}
                showAiAgentBanner={showAiAgentBanner}
                isMobileMenuActive={isMobileMenuActive}
                unreadSupportAnswerCount={unreadSupportAnswerCount}
                contactSalesAbTestVariation={contactSalesAbTestVariation}
              />
              {(replaceAuthButtons && !disableLoginFlow) && (
              <li
                className="jfRHeader--nav-menu-item-button"
                dangerouslySetInnerHTML={{ __html: replaceAuthButtons }}
              />
              )}
            </Navigation>
          </ScHeaderContainer>
        </ScHeader>
      </ScHeaderWrapper>
    </StyleSheetManager>
  );
};

HeaderBody.propTypes = {
  AppPickerRenderer: elementType,
  LoginGroupRenderer: elementType,
  logoProps: logoPropsPropTypes,
  logoSideLinkProps: logoSideLinkPropsPropTypes,
  customNavItems: array,
  disableLoginFlow: bool,
  hideLoginButton: bool,
  hideSignupButton: bool,
  forceRTL: bool,
  children: node,
  bgColor: string,
  fullWidth: bool,
  isMobileScreen: bool,
  isMobileMenuActive: bool,
  isSticky: bool,
  onMobileMenuButtonClick: func,
  onMobileSignupButtonClick: func,
  user: object.isRequired,
  theme: themePropType.isRequired,
  logHeaderAction: func,
  currentPath: string.isRequired,
  isLoggedIn: bool.isRequired,
  replaceAuthButtons: string,
  language: string.isRequired,
  defaultNavItems: array,
  menuStyles: object,
  isEnterprise: bool.isRequired,
  showAiAgentBanner: bool.isRequired,
  contactSalesAbTestVariation: string.isRequired,
  contactSalesAbTestVariationURL: string.isRequired
};

HeaderBody.defaultProps = {
  AppPickerRenderer: Fragment,
  LoginGroupRenderer: undefined,
  logoProps: logoPropsDefaultProps,
  logoSideLinkProps: logoSideLinkPropsDefaultProps,
  customNavItems: undefined,
  disableLoginFlow: false,
  hideLoginButton: false,
  hideSignupButton: false,
  forceRTL: false,
  children: null,
  bgColor: '',
  fullWidth: false,
  isMobileScreen: false,
  isMobileMenuActive: false,
  isSticky: false,
  onMobileMenuButtonClick: () => {},
  onMobileSignupButtonClick: () => {},
  logHeaderAction: () => {},
  replaceAuthButtons: '',
  defaultNavItems: [],
  menuStyles: {}
};

export default HeaderBody;
