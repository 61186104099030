import { cva } from 'class-variance-authority';
import { CVAType } from '../../types/system.types';
import { TagProps } from './tag.types';

// CVA Types
type TagCVAType = CVAType<Pick<TagProps, 'colorStyle' | 'size' | 'disabled'>>;
type TagSizeCVAType = CVAType<Pick<TagProps, 'size'>>

// Classes
export const tagCVA = cva<TagCVAType>(
  'magnet-tag inline-flex items-center color-navy-700 radius max-w-full overflow-hidden', {
    variants: {
      size: {
        small: 'h-5 text-xs px-1',
        medium: 'h-7 text-sm px-1.5',
        large: 'h-8 text-md px-2'
      },
      colorStyle: {
        blue: 'bg-blue-100',
        gray: 'bg-gray-50',
        green: 'bg-green-100',
        orange: 'bg-orange-100',
        purple: 'bg-purple-100',
        red: 'bg-red-100',
        yellow: 'bg-yellow-100'
      },
      disabled: {
        true: 'opacity-70',
        false: ''
      }
    }
  }
);

export const tagTextCVA = cva<TagSizeCVAType>('whitespace-nowrap truncate overflow-hidden', {
  variants: {
    size: {
      small: 'px-1',
      medium: 'px-1.5',
      large: 'px-2'
    }
  }
});

export const tagIconCVA = cva<TagSizeCVAType>('color-current shrink-0', {
  variants: {
    size: {
      small: 'w-3 h-3',
      medium: 'w-4 h-4',
      large: 'w-4 h-4'
    }
  }
});
