import { MENU_TYPES } from '../constants';

export const pdfEditorMenu = {
  name: 'pdf-editor',
  menuTitle: '',
  menuItems: [
    {
      name: 'pdfFeatures',
      title: 'Features',
      url: '/products/pdf-editor/features/'
    },
    {
      name: 'pdfTemplates',
      title: 'PDF Templates',
      url: '/pdf-templates/'
    },
    {
      name: 'fillablePDFForms',
      title: 'Fillable PDF Forms',
      url: '/fillable-pdf-form-creator/'
    },
    {
      name: 'pdfFaq',
      title: 'FAQ',
      url: '/products/pdf-editor/#FAQ'
    },
    {
      name: 'pdfWebinar',
      title: 'Webinar',
      url: '/products/pdf-editor/jotform-pdf-features-you-need-to-know-webinar/'
    }
  ]
};

export const pdfEditorNavItem = {
  name: 'pdf-editor',
  menuProps: pdfEditorMenu,
  linkProps: {
    renderAs: 'button',
    title: 'PDF Editor',
    menuType: MENU_TYPES.PDF_EDITOR
  }
};
