import React, { useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import { func, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import {
  PrefilledLeadFormAside, ALL_TEXTS, AUTOMATED_NURTURING_SOURCES, gaAttributesPropType, gaFormTrackingItemsPropType, userPropType
} from '@jotforminc/enterprise-promotions-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import '../../../../styles/educationToolkitModal.scss';

const EducationReportModal = (({
  user,
  title,
  middleTitle,
  gaAttributes,
  gaAttributesClose,
  gaFormTrackingItems,
  descriptionText,
  onClose,
  onCloseClick,
  onFormSubmit,
  ...props
}) => {
  const { EDUCATION_REPORT_MODAL: EDUCATION_REPORT_MODAL_NUR } = AUTOMATED_NURTURING_SOURCES;

  const uikitModalRef = useRef(null);

  const handleCloseClick = () => {
    uikitModalRef.current?.hide();
  };

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={prps => <ContentRenderer {...gaAttributes} {...prps} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={onClose}
    >
      <div className="content-wrapper">
        {/* left col */}
        <div className="left-col">
          <div className='title-section'>
            {title && <h4 className='title'>{t(title)}</h4>}
            {middleTitle && <h4 className='middleTitle'>{t(middleTitle)}</h4>}
            {descriptionText && <p className='description'>{t(descriptionText)}</p>}
          </div>
          <PrefilledLeadFormAside
            user={user}
            formID='230313713253949'
            devFormID='241862485601963'
            gaAttributes={gaAttributes}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(title)}
            automatedNurturingSource={EDUCATION_REPORT_MODAL_NUR}
            classNames="form-iframe"
            onFormSubmit={onFormSubmit}
            {...props}
          />
        </div>
        {/* right col */}
        <div className="right-col">
          <img
            alt={t(ALL_TEXTS.EDUCATION_REPORT_TITLE)}
            src="//cdn.jotfor.ms/assets/img/enterprise/education-report-modal/cover.png"
            className="visual"
          />
        </div>

      </div>
    </Modal>
  );
});

EducationReportModal.propTypes = {
  title: string,
  middleTitle: string,
  descriptionText: string,
  explanationText: string,
  user: userPropType.isRequired,
  gaAttributes: gaAttributesPropType.isRequired,
  gaAttributesClose: gaAttributesPropType.isRequired,
  gaFormTrackingItems: gaFormTrackingItemsPropType.isRequired,
  formUiModifier: string,
  onClose: func,
  onCloseClick: func,
  onFormSubmit: func
};

EducationReportModal.defaultProps = {
  title: '',
  middleTitle: '',
  descriptionText: '',
  explanationText: '',
  formUiModifier: '',
  onClose: f => f,
  onCloseClick: f => f,
  onFormSubmit: f => f
};

export default EducationReportModal;
