import {
  bool, element, oneOf, shape, string
} from 'prop-types';
import { THEME_MAP, USER_TYPES } from '../constants';

export const themePropType = oneOf([THEME_MAP.DARK, THEME_MAP.LIGHT, THEME_MAP.GRAY]);

export const userTypePropType = oneOf([USER_TYPES.USER, USER_TYPES.FORM_USER]);

export const logoPropsPropTypes = shape({
  logoUrl: string,
  logoFullLight: bool,
  enterpriseLogoSrc: string,
  customLogoAsReactElement: element,
  customLogoAsHtmlString: string,
  customDarkLogoAsHtmlString: string
});

export const logoPropsDefaultProps = {
  logoUrl: '/',
  logoFullLight: false,
  enterpriseLogoSrc: '',
  customLogoAsReactElement: null,
  customLogoAsHtmlString: '',
  customDarkLogoAsHtmlString: ''
};

export const logoSideLinkPropsPropTypes = shape({
  sideLinkUrl: string,
  sideLinkText: string,
  sideLinkTextColor: string,
  sideLinkClassName: string,
  dividerColor: string,
  sideLinkLogoAsHtmlString: string
});

export const logoSideLinkPropsDefaultProps = {
  sideLinkUrl: '#',
  sideLinkText: '',
  sideLinkTextColor: '',
  sideLinkClassName: '',
  dividerColor: '',
  sideLinkLogoAsHtmlString: ''
};
