import { renderAsset } from '@jotforminc/enterprise-promotions-utils';
import { HealthcareReportModal } from '../components';

const renderHealthcareReportModal = ({
  targetSelector,
  ...props
}) => {
  renderAsset({
    Asset: HealthcareReportModal,
    rootElementId: targetSelector,
    ...props
  });
};

export default renderHealthcareReportModal;
