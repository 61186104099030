import { t } from '@jotforminc/translation';
import { TEXTS } from '../constants';

export const myFormsNavItem = {
  name: 'myForms',
  linkProps: {
    url: '/myforms',
    title: t(TEXTS.MY_FORMS)
  }
};
