import Tracking from '@jotforminc/tracking';

// A/B Test: landingReactCommonHeader
export const FS_PROJECT_NAME = 'landingReactCommonHeader';

// top 10 landing pages has the most traffic included in the a/b test
export const allowedDirectories = [
  '/products/pdf-editor',
  '/fillable-pdf-form-creator',
  '/google-forms-alternative',
  '/surveys',
  '/rsvp-tool',
  '/poll-maker',
  '/microsoft-forms-alternative',
  '/online-waiver-forms',
  '/hipaa',
  '/online-payments'
];

export const isFsEnabledOnPage = () => {
  let { pathname } = window.location;
  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1);
  }
  return allowedDirectories.includes(pathname);
};

const isInOnePercent = () => Math.floor(Math.random() * 100) + 1 === 1;

export const identifyUserForFullstory = ({
  projectName,
  username = '',
  callback = f => f,
  isEnabled = false
}) => {
  if (!isEnabled || !username || !isInOnePercent()) return;
  if (!Tracking.FSisInitialized()) {
    Tracking.enableFS();
  }
  Tracking.identify(username, {
    displayName: username,
    [`${projectName}_bool`]: true
  });
  if (typeof callback === 'function') {
    callback();
  }
};
