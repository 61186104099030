import React from 'react';
import {
  shape, bool, string, array
} from 'prop-types';
import {
  THEME_MAP, userTypePropType, logoPropsPropTypes, themePropType,
  logoPropsDefaultProps, logoSideLinkPropsDefaultProps, logoSideLinkPropsPropTypes
} from '@jotforminc/header-body';
import UserProvider from './UserProvider';
import Header from '../Header';
import MobileMenuProvider from './MobileMenuProvider';
import JotformActionsProvider from './JotformActionsProvider';
import ModeProvider from './ModeProvider';

const HeaderProvider = ({
  user,
  userType,
  isLoggedIn,
  theme,
  bgColor,
  language,
  forceRTL,
  logoProps,
  fullWidth,
  stickyMode,
  customNavItems,
  hideAccountBox,
  appPickerProps,
  disableLoginFlow,
  hideLoginButton,
  hideSignupButton,
  logoSideLinkProps,
  replaceAuthButtons,
  showOneTapLoginArea,
  defaultNavItems,
  currentPath,
  menuStyles,
  isEnterprise,
  languageSwitcher,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL,
  hostname
}) => {
  return (
    <UserProvider
      user={user}
      userType={userType}
      isLoggedIn={isLoggedIn}
    >
      <JotformActionsProvider>
        <MobileMenuProvider>
          <ModeProvider stickyMode={stickyMode}>
            <Header
              theme={theme}
              bgColor={bgColor}
              language={language}
              forceRTL={forceRTL}
              logoProps={logoProps}
              fullWidth={fullWidth}
              customNavItems={customNavItems}
              hideAccountBox={hideAccountBox}
              appPickerProps={appPickerProps}
              disableLoginFlow={disableLoginFlow}
              hideLoginButton={hideLoginButton}
              hideSignupButton={hideSignupButton}
              logoSideLinkProps={logoSideLinkProps}
              replaceAuthButtons={replaceAuthButtons}
              showOneTapLoginArea={showOneTapLoginArea}
              defaultNavItems={defaultNavItems}
              currentPath={currentPath}
              menuStyles={menuStyles}
              isEnterprise={isEnterprise}
              languageSwitcher={languageSwitcher}
              contactSalesAbTestVariation={contactSalesAbTestVariation}
              contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
              hostname={hostname}
            />
          </ModeProvider>
        </MobileMenuProvider>
      </JotformActionsProvider>
    </UserProvider>
  );
};

HeaderProvider.propTypes = {
  user: shape({}),
  userType: userTypePropType,
  isLoggedIn: bool,
  theme: themePropType,
  bgColor: string,
  language: string,
  forceRTL: bool,
  logoProps: logoPropsPropTypes,
  fullWidth: bool,
  stickyMode: bool,
  customNavItems: array,
  hideAccountBox: bool,
  appPickerProps: shape({}),
  disableLoginFlow: bool,
  hideLoginButton: bool,
  hideSignupButton: bool,
  logoSideLinkProps: logoSideLinkPropsPropTypes,
  replaceAuthButtons: string,
  showOneTapLoginArea: bool,
  defaultNavItems: array,
  currentPath: string,
  hostname: string,
  menuStyles: shape({}),
  isEnterprise: bool,
  languageSwitcher: bool,
  contactSalesAbTestVariation: string,
  contactSalesAbTestVariationURL: string
};

HeaderProvider.defaultProps = {
  user: global.window?.__jfCommonHeaderProps?.user || global.window?.user,
  userType: global.window?.userType || global.window?.__jfCommonHeaderProps?.userType,
  isLoggedIn: global.window?.__jfCommonHeaderProps?.isLoggedIn,
  theme: global.window?.__jfCommonHeaderProps?.theme || THEME_MAP.DARK,
  bgColor: global.window?.__jfCommonHeaderProps?.bgColor || null,
  language: global.window?.__jfCommonHeaderProps?.language || 'en-US',
  forceRTL: false,
  logoProps: global.window?.__jfCommonHeaderProps?.logoProps || logoPropsDefaultProps,
  fullWidth: false,
  stickyMode: global.window?.__jfCommonHeaderProps?.stickyMode || false,
  customNavItems: global.window?.__jfCommonHeaderProps?.customNavItems,
  hideAccountBox: global.window?.__jfCommonHeaderProps?.hideAccountBox || false,
  appPickerProps: global.window?.__jfCommonHeaderProps?.appPickerProps || {},
  disableLoginFlow: global.window?.__jfCommonHeaderProps?.disableLoginFlow || false,
  hideLoginButton: global.window?.__jfCommonHeaderProps?.hideLoginButton || false,
  hideSignupButton: global.window?.__jfCommonHeaderProps?.hideSignupButton || false,
  logoSideLinkProps: global.window?.__jfCommonHeaderProps?.logoSideLinkProps || logoSideLinkPropsDefaultProps,
  replaceAuthButtons: global.window?.__jfCommonHeaderProps?.replaceAuthButtons || '',
  showOneTapLoginArea: false,
  defaultNavItems: global.window?.__jfCommonHeaderProps?.defaultNavItems || [],
  currentPath: global.window?.__jfCommonHeaderProps?.currentPath || (global.window?.location || global.location)?.pathname,
  hostname: global.window?.__jfCommonHeaderProps?.hostname || (global.window?.location || global.location)?.hostname,
  menuStyles: global.window?.__jfCommonHeaderProps?.menuStyles || {},
  isEnterprise: global.window?.__jfCommonHeaderProps?.isEnterprise || false,
  languageSwitcher: global.window?.__jfCommonHeaderProps?.languageSwitcher || { visible: false },
  contactSalesAbTestVariation: global.window?.__jfCommonHeaderProps?.contactSalesAbTestVariation || '',
  contactSalesAbTestVariationURL: global.window?.__jfCommonHeaderProps?.contactSalesAbTestVariationURL || ''
};

export default HeaderProvider;
