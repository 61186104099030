import React, { useEffect } from 'react';
import { func } from 'prop-types';
import {
  ASSET_IDENTIFIER, ALL_TEXTS, GA_FORM_TRACKING_ITEMS, userPropType,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, mapCallbacksToGaTrackingItems, setEPActions
} from '@jotforminc/enterprise-promotions-utils';
import EducationReportModal from './EducationReportModal';

// Educator's Technology Toolkit Report Modal
const EttEducationGatedContentModal = (({
  user,
  onClose
}) => {
  const { CAMPAIGN: { ETT_EDUCATION_GATED_CONTENT_MODAL: target } } = ASSET_IDENTIFIER;
  let { [target]: gaFormTrackingItems } = GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);
    setEPActions({ asset: target, target, action: 'close' });
    onClose();
  };

  const handleCloseClick = () => {
    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  const handleGetInsightsNowClick = () => {
    setEPActions({ asset: target, target: 'getInsightsNowButton', action: 'click' });
  };

  const handleFormSubmit = () => {
    setEPActions({ asset: target, target, action: 'formSubmit' });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleGetInsightsNowClick]);

  return (
    <EducationReportModal
      user={user}
      middleTitle={ALL_TEXTS.EDUCATION_REPORT_DOWNLOAD_TITLE}
      gaAttributes={gaAttributes}
      gaAttributesClose={gaAttributesClose}
      gaFormTrackingItems={gaFormTrackingItems}
      onClose={handleClose}
      onCloseClick={handleCloseClick}
      onFormSubmit={handleFormSubmit}
    />
  );
});

EttEducationGatedContentModal.propTypes = {
  user: userPropType.isRequired,
  onClose: func
};

EttEducationGatedContentModal.defaultProps = {
  onClose: f => f
};

export default EttEducationGatedContentModal;
