const hasCookieConsent = consentName => {
  const cookiebot = window.Cookiebot;
  const consent = cookiebot?.consent?.[consentName];
  if (!consentName || !cookiebot) return true;
  if (cookiebot?.consent?.stamp === '0') return true;
  if (consent) return true;
  return false;
};

export const setCookie = (name, value, ttl, consentName) => {
  let expires = '';
  if (window.jotCookie.hasCookieConsent(consentName)) {
    if (ttl) {
      const date = new Date();
      date.setTime(date.getTime() + (ttl * 24 * 60 * 60 * 1000));
      expires = `expires=${date.toUTCString()};`;
    }
    document.cookie = `${name}=${value};${expires} path=/`;
  }
  document.cookie = `${name}=${value};${expires} path=/`;
};

export const readCookie = name => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }

  return null;
};

window.jotCookie = { hasCookieConsent, setCookie, readCookie };
