import { getComponentName } from './namings';

import CampaignAssetList from '../components/assets/assetList/CampaignAssetList';
import PromotionAssetList from '../components/assets/assetList/PromotionAssetList';
import LaunchAssetList from '../components/assets/assetList/LaunchAssetList';
import AnnouncementAssetList from '../components/assets/assetList/AnnouncementAssetList';

const AssetList = {
  ...CampaignAssetList,
  ...PromotionAssetList,
  ...LaunchAssetList,
  ...AnnouncementAssetList
};

export const getAssetComponent = ({ assetInfo, assetType }) => {
  const componentName = getComponentName({ ...assetInfo, assetType });
  return componentName ? AssetList[componentName] : null;
};
