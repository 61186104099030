import { bool } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Tracking from '@jotforminc/tracking';

import { userPropType } from '@jotforminc/enterprise-promotions-utils';
import { AdvertisementLeadFlowBanner } from '../../../Banners';
import { useActionManager } from '../../../../hooks';

// A/B Test: epAdLeadFlow
const AdvertisementLeadFlow = ({
  user,
  isExitIntentModalAvailable,
  ...props
}) => {
  const [actionsLoaded, registerJotformAction] = useActionManager(user, 'epAdLeadFlow');
  const logAbTestActionRef = useRef(f => f);

  useEffect(() => {
    logAbTestActionRef.current = registerJotformAction;
    Tracking.enableFS({ advertisementLeadFlow_bool: true });
  }, [actionsLoaded]);

  return (
    <AdvertisementLeadFlowBanner
      {...props}
      user={user}
      logAbTestAction={logAbTestActionRef.current}
      isExitIntentModalAvailable={isExitIntentModalAvailable}
    />
  );
};

AdvertisementLeadFlow.propTypes = {
  isExitIntentModalAvailable: bool,
  user: userPropType.isRequired
};

AdvertisementLeadFlow.defaultProps = {
  isExitIntentModalAvailable: false
};

export default AdvertisementLeadFlow;
