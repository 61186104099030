import { renderFerpaBanner } from '@jotforminc/enterprise-promotions';
import { insertAfter } from '../../utils';

// render BlogEducationSideBanner at /what-is-ferpa?epfp=true
export const initEpFerpaBanner = user => {
  if (window.location.pathname !== '/what-is-ferpa/') return;

  const createFormButtonEl = document.querySelector('.js-sidefixedmenu .external-link');
  if (!createFormButtonEl) return;

  const bannerRootEl = document.createElement('div');
  bannerRootEl.setAttribute('id', 'ferpa-banner-root');
  insertAfter(createFormButtonEl, bannerRootEl);

  renderFerpaBanner({
    user,
    rootEl: bannerRootEl
  });
};
