import { MENU_TYPES } from '../constants';

export const supportMenu = {
  name: 'support',
  menuTitle: 'GET HELP',
  columnCount: 1,
  menuItems: [
    {
      name: 'contactSupport',
      title: 'Contact Support',
      url: '/contact/'
    },
    {
      name: 'mySupportRequests',
      title: 'My Support Requests',
      url: '/answers/asked',
      loginRequired: true
    },
    {
      name: 'helpCenter',
      title: 'Help Center',
      url: '/answers/'
    },
    {
      name: 'faq',
      title: 'FAQ',
      url: '/faq/'
    }
  ],
  sideMenuTitle: 'LEARN',
  sideMenuItems: [
    {
      name: 'userGuide',
      title: 'User Guide',
      url: '/help/'
    },
    {
      name: 'jotformBooks',
      title: 'Jotform Books',
      url: '/books/'
    },
    {
      name: 'blog',
      title: 'Blog',
      url: '/blog/'
    },
    {
      name: 'videos',
      title: 'Videos',
      url: 'https://www.youtube.com/user/TheJotform'
    }, {
      name: 'academy',
      title: 'Jotform Academy',
      url: '/academy/'
    }
  ]
};

export const supportNavItem = {
  name: 'support',
  menuProps: supportMenu,
  linkProps: {
    renderAs: 'button',
    title: 'Support',
    menuType: MENU_TYPES.SUPPORT
  }
};
