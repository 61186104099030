import React from 'react';
import cx from 'classnames';
import { bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { TEXTS } from '../../constants';
import HipaaSvg from '../../assets/svg/hipaa.svg';
import { isDarkTheme } from '../../utils';
import { themePropType } from '../../propTypes';

const BadgeHipaa = ({ theme, isMobileMenuActive }) => {
  return (
    <div
      className={cx('jfRHeader--hipaa', {
        'isDarkTheme isNotMobileMenuActive': isDarkTheme(theme) && !isMobileMenuActive,
        'isNotDarkTheme isMobileMenuActive': !isDarkTheme(theme) || isMobileMenuActive
      })}
    >
      <HipaaSvg className="jfRHeader--hipaa-logo" />
      <span className={cx('jfRHeader--hipaa-text locale', {
        isDark: isDarkTheme(theme) && !isMobileMenuActive,
        isLight: !isDarkTheme(theme) || isMobileMenuActive
      })}
      >
        {t(TEXTS.HIPAA_COMPLIANCE)}
      </span>
    </div>
  );
};

BadgeHipaa.propTypes = {
  theme: themePropType.isRequired,
  isMobileMenuActive: bool
};

BadgeHipaa.defaultProps = {
  isMobileMenuActive: false
};

export default BadgeHipaa;
