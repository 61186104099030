import React from 'react';
import cx from 'classnames';
import { array, bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { splitArray } from '../../utils';

const PdfEditorMenuType = ({ menuItems, isOpen }) => {
  const PdfEditorMenuTypeClasses = cx('jfRHeader--nav-sub-menu jfPDFEditor', {
    isOpen
  });

  return (
    <>
      {menuItems.length > 0 && (
        <ul className={PdfEditorMenuTypeClasses}>
          <li className="jfRHeader--nav-sub-menu-left-banner">
            <a href="/products/pdf-editor/">
              <img
                src="https://cdn01.jotfor.ms/assets/img/jfHeader/v2/pdf-editor-logo.svg?v=0.1"
                class="jfh-pdf-logo"
                alt="Jotform PDF Editor Logo"
              />
              <p class="locale">{t('Automatically create polished, designed documents')}</p>
              <img
                src="https://cdn01.jotfor.ms/assets/img/jfHeader/v2/pdf-editor.png?v=0.1"
                class="jfh-pdf-banner"
                alt="Jotform PDF Editor Banner"
              />
            </a>
          </li>
          {splitArray(menuItems).map(items => (
            <ul>
              {items.map(({ name, title, url }) => (
                <li key={name} className="jfRHeader--nav-sub-menu-item">
                  <a href={url} className="locale">{t(title)}</a>
                </li>
              ))}
            </ul>
          ))}
        </ul>
      )}
    </>
  );
};

PdfEditorMenuType.propTypes = {
  menuItems: array.isRequired,
  isOpen: bool.isRequired
};

export default PdfEditorMenuType;
