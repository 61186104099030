const emailLinks = [
  {
    name: 'constantContact',
    title: 'Constant Contact',
    url: '/integrations/constant-contact',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/constant-contact.svg'
  },
  {
    name: 'mailchimp',
    title: 'Mailchimp',
    url: '/integrations/mailchimp',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/mailchimp.svg'
  },
  {
    name: 'activeCampaign',
    title: 'ActiveCampaign',
    url: '/integrations/activecampaign',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/active-campaign.svg'
  },
  {
    name: 'aweber',
    title: 'AWeber',
    url: '/integrations/aweber',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/aweber.svg'
  },
  {
    name: 'campaignMonitor',
    title: 'Campaign Monitor',
    url: '/integrations/campaign-monitor',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/campaign-monitor.svg'
  },
  {
    name: 'getResponse',
    title: 'GetResponse',
    url: '/integrations/getresponse',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/get-response.svg'
  },
  {
    name: 'googleContacts',
    title: 'Google Contacts',
    url: '/integrations/google-contacts',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-contacts.svg'
  },
  {
    name: 'iContact',
    title: 'iContact',
    alt: 'Blue and Big I Image for iContact',
    url: '/integrations/icontact',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/icontact.svg'
  }
];

export default emailLinks;
