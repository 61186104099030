export const customRedirectionOnSignUp = () => {
  if (window.setCustomFunction_signup) {
    window.customFunction_signup();
    return true;
  }
  return false;
};

export const customRedirectionOnLogIn = () => {
  if (window.setCustomFunction_login) {
    window.customFunction_login();
    return true;
  }
  return false;
};
