import { lazy } from 'react'
export const Y2024BacktopaidPricingBadgeV1 = lazy(() => import('../../assets/campaigns/2024/backtopaid/pricing-badge/v1.js'));
export const Y2024BacktopaidFlyinV1 = lazy(() => import('../../assets/campaigns/2024/backtopaid/flyin/v1.js'));
export const Y2024BlackfridayBannerIndex = lazy(() => import('../../assets/campaigns/2024/blackfriday/banner/index.js'));
export const Y2024NewuserBannerV2 = lazy(() => import('../../assets/campaigns/2024/newuser/banner/v2.js'));
export const Y2024NewuserDummyExitModalV2 = lazy(() => import('../../assets/campaigns/2024/newuser/dummy-exit-modal/v2.js'));
export const Y2024NewuserLimitDialogV2 = lazy(() => import('../../assets/campaigns/2024/newuser/limit-dialog/v2.js'));
export const Y2024NewuserMyaccountBadgeV2 = lazy(() => import('../../assets/campaigns/2024/newuser/myaccount-badge/v2.js'));
export const Y2024NewuserPricingBadgeV2 = lazy(() => import('../../assets/campaigns/2024/newuser/pricing-badge/v2.js'));
export const Y2024OverquotauserBannerIndex = lazy(() => import('../../assets/campaigns/2024/overquotauser/banner/index.js'));
export const Y2024RegularfiftydiscountAccountBoxV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/account-box/v2.js'));
export const Y2024RegularfiftydiscountExitModalV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/exit-modal/v2.js'));
export const Y2024RegularfiftydiscountFlyinV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/flyin/v2.js'));
export const Y2024RegularfiftydiscountPricingBadgeV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/pricing-badge/v2.js'));
export const Y2024SilveroneExitModalV1 = lazy(() => import('../../assets/campaigns/2024/silverone/exit-modal/v1.js'));
export const Y2024SilveroneFlyinV1 = lazy(() => import('../../assets/campaigns/2024/silverone/flyin/v1.js'));
export const Y2024SilveroneLightboxV1 = lazy(() => import('../../assets/campaigns/2024/silverone/lightbox/v1.js'));
export const Y2024SummerBannerIndex = lazy(() => import('../../assets/campaigns/2024/summer/banner/index.js'));
export const Y2024SummerLightboxIndex = lazy(() => import('../../assets/campaigns/2024/summer/lightbox/index.js'));
export const Y2024TestcampaignBannerIndex = lazy(() => import('../../assets/campaigns/2024/testcampaign/banner/index.js'));
export const Y2024TestcampaignBannerV2 = lazy(() => import('../../assets/campaigns/2024/testcampaign/banner/v2.js'));