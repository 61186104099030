import React from 'react';
import { shape, string } from 'prop-types';

import GrowthAssetRenderer from './GrowthAssetRenderer';

const GrowthAssetManager = ({ assetProps, assetType }) => {
  return <GrowthAssetRenderer campaignInfo={global.window?.campaignInfo} assetProps={assetProps} assetType={assetType} />;
};

GrowthAssetManager.propTypes = {
  assetProps: shape({}),
  assetType: string.isRequired
};

GrowthAssetManager.defaultProps = {
  assetProps: {}
};

export default GrowthAssetManager;
