import { renderHealthcareReportModal } from '@jotforminc/enterprise-promotions';
import { readCookie } from '@jotforminc/utils';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { StorageHelper } from '@jotforminc/storage-helper';

import { userSeenCampaign, getCurrentDateWithoutTimeZone, isGuest } from '../../utils';
import { enabledPages } from './enabledPages';

export const initEpHealthcareReportModal = async user => {
  const isAvailableInPage = enabledPages.includes(window.location.pathname);
  if (!isAvailableInPage) return;

  const isEnabled = true;
  const urlParam = 'hcrm';
  const seenAtKey = 'enterprise2024HealthcareReportModalSeenAt';
  const urlParams = new URLSearchParams(window.location.search);
  const hasUrlParam = urlParams.has(urlParam);
  const isUserHasAdCookie = readCookie('adwords_mode') === 'on';
  const isGuestUser = isGuest(user);
  const shouldRenderForGuest = isGuestUser && isEnabled && !isEnterprise() && !StorageHelper.getLocalStorageItem({ key: seenAtKey }) && !isUserHasAdCookie;

  try {
    const regularUserNotSeenYet = !(await userSeenCampaign(seenAtKey));
    const shouldRenderForRegularUser = !isGuestUser && isEnabled && !isEnterprise && !isUserHasAdCookie && regularUserNotSeenYet;
    const shouldRender = shouldRenderForGuest || shouldRenderForRegularUser || hasUrlParam;

    if (!shouldRender) return;

    // hotfix for guest users, user settings data return undefined from backend
    if (isGuestUser) {
      StorageHelper.setLocalStorageItem({ key: seenAtKey, value: getCurrentDateWithoutTimeZone() });
    }

    let isInitiated = false;
    document.documentElement?.addEventListener('mouseleave', () => {
      if (isInitiated) return;
      isInitiated = true;
      renderHealthcareReportModal({
        user,
        targetSelector: '#healthcare-report-modal-root'
      });
    });
  } catch (error) {
    console.log(`Error on initializing epHealthcareReportModal: ${error}`);
  }
};
