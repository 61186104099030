import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  ReactChild,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { cx } from 'class-variance-authority';

import {
  autoUpdate,
  flip,
  useFloating,
  useInteractions,
  useListNavigation,
  useClick,
  useDismiss,
  useRole,
  FloatingFocusManager,
  FloatingList,
  size as floatingSize,
  offset
} from '@floating-ui/react';

import {
  IconAngleDown,
  IconLockFilled,
  IconMagnifyingGlass,
  IconXmarkSm
} from '@jotforminc/svg-icons';

import {
  DropdownProps,
  dropdownDefaultProps
} from './dropdown.types';

import { BaseInput, BaseInputAddon } from '../Input/BaseInput';

import {
  DropdownContext, DropdownOptionType, HandleGroupSelectParams, HandleSelectParams, useFormControl
} from '../../contexts';

import { dropdownListCVA, dropdownResultNotFoundCVA, dropdownSearchInputCVA } from './dropdown.cva';
import { arraysEqualsCheck, flattenChildren } from '../../utils';
import { InputText } from '../Input';
import { Tag } from '../Tag';

const allowedKeys = ['ArrowDown', 'ArrowUp', 'Enter'];

export const Dropdown = (props: DropdownProps):JSX.Element => {
  const {
    colorStyle,
    disabled,
    readOnly,
    theme,
    size,
    required,
    placeholder = 'Select an option',
    noResultFoundText = 'No results found.',
    searchable,
    placeholderIcon,
    ghost,
    value: defaultValue,
    onChange,
    id,
    children,
    className,
    multiple = false,
    expand = false,
    showCountOnly,
    style
  } = useFormControl<DropdownProps>(props);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedOptionArray, setSelectedOptionArray] = useState<DropdownOptionType[] | null>(null);
  const [selectedOption, setSelectedOption] = useState<DropdownOptionType | null>();
  const [searchTerm, setSearchTerm] = useState('');
  const [resultNotFound, setResultNotFound] = useState(false);

  useEffect(() => {
    const defaultValueArr = (typeof defaultValue === 'string' ? [defaultValue] : defaultValue) || [];
    let childrenArray:ReactChild[] = [];

    if (children) {
      childrenArray = flattenChildren({
        children,
        depth: 3,
        filter: 'DropdownOption',
        group: 'DropdownGroup'
      });

      if (!childrenArray) {
        return;
      }
    }

    if (!defaultValue) { return; }

    if (arraysEqualsCheck(defaultValueArr, selectedOptionArray?.map(o => o.value)) || arraysEqualsCheck(defaultValueArr, selectedOption ? [selectedOption?.value] : [])) {
      const updatedOptions = defaultValueArr?.map(defValue => {
        const child = childrenArray.find(component => (component as ReactElement)?.props?.value === defValue) as ReactElement;
        return child?.props;
      });

      setSelectedOption(updatedOptions[0]);
      setSelectedOptionArray(updatedOptions);
    } else {
      const defaultValues = childrenArray?.filter(component => (defaultValueArr?.includes((component as ReactElement)?.props?.value))).map(component => (component as ReactElement).props);
      setSelectedOptionArray(defaultValues);
      setSelectedOption(defaultValues[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, defaultValue]);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      flip(),
      offset(6),
      floatingSize({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight - 16}px`,
            minWidth: `${rects.reference.width}px`,
            marginTop: 0
          });
        }
      })
    ]
  });

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);
  const searchInputRef = useRef(null);

  const handleSelect = useCallback(({
    index, children: singleChildren, icon: singleIcon, value
  }: HandleSelectParams) => {
    setSelectedIndex(index);
    setIsOpen(false);
    if (index !== null) {
      setSelectedOption({ children: singleChildren, icon: singleIcon, value });
      if (onChange) onChange(value);
    }
  }, [onChange, setIsOpen]);

  const handleMultiSelect = useCallback(({
    checked, value, tagStyles, tagColorStyle, children: tagText, icon: OptionIcon
  }) => {
    const newSelectedOptionArray = checked ? [...selectedOptionArray || [], {
      value,
      tagStyles,
      tagColorStyle,
      children: tagText as string,
      icon: OptionIcon
    }] : (selectedOptionArray?.filter(option => option.value !== value) || []);

    setSelectedOptionArray(newSelectedOptionArray);
    if (onChange) onChange(newSelectedOptionArray.map(o => o.value));
  }, [onChange, selectedOptionArray]);

  const handleGroupSelect = useCallback(({
    checked, allOptions, childrenArray
  }: HandleGroupSelectParams) => {
    const group:DropdownOptionType[] = (checked ? allOptions?.filter(
      (obj: DropdownOptionType, index: number, self: DropdownOptionType[]) => index === self.findIndex((opt: DropdownOptionType) => (opt?.value === obj?.value))
    ) : selectedOptionArray?.filter(val => !childrenArray?.find((ch: DropdownOptionType) => ch?.value === val?.value))) || [];

    setSelectedOptionArray(group);
    if (onChange) onChange(group.map(g => g.value));
  }, [onChange, selectedOptionArray]);

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([listNav, click, dismiss, role]);

  const dropdownContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
      size,
      theme,
      multiple,
      selectedOptionArray,
      setSelectedOptionArray,
      handleMultiSelect,
      handleGroupSelect
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect, size, theme, multiple, selectedOptionArray, setSelectedOptionArray, handleMultiSelect, handleGroupSelect]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement> | KeyboardEvent) => {
    if (allowedKeys.includes((e as KeyboardEvent).key)) {
      return;
    }

    const term = (e?.target as HTMLInputElement)?.value;
    e.stopPropagation();
    setSearchTerm(term);
  };

  const hideGroup = (e?: HTMLElement | null) => {
    if (!e) return;
    const group = e?.closest('.magnet-dropdown-group') as HTMLElement;

    if (group) {
      const hasNotDisabled = group.querySelector('[role="option"]:not([disabled])');

      if (!hasNotDisabled) {
        group.style.setProperty('display', 'none');
      } else {
        group.style.removeProperty('display');
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm('');
    }

    elementsRef.current.forEach(element => {
      // search in checkbox labels or option texts
      const searchIn = multiple ? element?.closest('label') : element;
      if (!searchIn) return;
      if (!searchIn?.textContent?.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchIn?.style.setProperty('display', 'none');
        element?.setAttribute('disabled', 'true');
      } else {
        searchIn?.style.removeProperty('display');
        element?.removeAttribute('disabled');
      }
      hideGroup(searchIn);
    });

    if (searchTerm.length > 0) {
      setResultNotFound(!elementsRef.current.find(element => !element?.hasAttribute('disabled') && !element?.hasAttribute('data-dropdown-group-checkbox')));
    } else {
      setResultNotFound(false);
    }
  }, [searchTerm, isOpen, multiple]);

  const clearSearch = () => {
    setSearchTerm('');
    if (searchInputRef.current) (searchInputRef.current as HTMLInputElement).focus();
  };

  const isDisabled = readOnly || disabled;
  const selectedOptionForMultiple = multiple && selectedOptionArray ? selectedOptionArray.length > 0 : false;
  const startIcon = selectedOption && !multiple ? selectedOption?.icon : placeholderIcon;

  const handleTagClick = (e: MouseEvent<HTMLElement>, option: DropdownOptionType) => {
    e.stopPropagation();
    if (selectedOptionArray) {
      const filteredOptionArray = selectedOptionArray?.filter(filteredOption => filteredOption.value !== option.value);
      if (onChange) onChange(filteredOptionArray.map(f => f.value));
      setSelectedOptionArray(filteredOptionArray);
    }
    if (refs?.reference?.current) {
      (refs?.reference?.current as HTMLElement).focus();
    }
  };

  const countMarkup = () => {
    const countText = `${selectedOptionArray?.length} option${selectedOptionArray && selectedOptionArray.length > 1 ? 's' : ''} selected`;
    return (typeof showCountOnly === 'function' ? showCountOnly(selectedOptionArray?.length || 0) : countText);
  };

  const selectedOptionMarkup = ():ReactNode | undefined => {
    if (!multiple) {
      return selectedOption?.children;
    }
    if (multiple && selectedOptionForMultiple) {
      return !showCountOnly ? (
        <span
          className={cx('flex overflow-hidden magnet-dropdown-tags', { 'flex-wrap': expand }, {
            'gap-1': size !== 'large',
            'gap-1.5': size === 'large'
          })}
        >
          {selectedOptionArray?.map(option => (
            <Tag
              size={size}
              icon={option?.icon}
              colorStyle={option?.tagColorStyle}
              key={`tag-${option?.value}`}
              className="shrink-0"
              onClick={e => handleTagClick(e, option)}
              style={option?.tagStyles}
              disabled={isDisabled}
            >
              {option?.children}
            </Tag>
          ))}
        </span>
      ) : countMarkup();
    }
    return undefined;
  };

  return (
    <>
      <BaseInput
        as="dropdown"
        colorStyle={colorStyle}
        theme={theme}
        size={size}
        readOnly={!!readOnly}
        ref={refs.setReference}
        disabled={disabled}
        tabIndex={0}
        required={required}
        ghost={ghost}
        id={id}
        className={className}
        placeholder={placeholder}
        expand={expand}
        addonStart={(
          <BaseInputAddon
            direction="start"
            size={size}
            theme={theme}
            disabled={disabled}
            icon={startIcon}
            useInputColor={multiple ? selectedOptionForMultiple : !!selectedOption}
          />
        )}
        addonEnd={(
          <BaseInputAddon
            size={size}
            direction="end"
            icon={readOnly ? IconLockFilled : IconAngleDown}
            theme={theme}
          />
        )}
        {... !isDisabled ? getReferenceProps() : {}}
      >
        {selectedOptionMarkup()}
      </BaseInput>
      <DropdownContext.Provider value={dropdownContext}>
        {isOpen && !isDisabled && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{ ...floatingStyles, ...style }}
              className={dropdownListCVA({ theme })}
              {...getFloatingProps()}
            >
              {!!searchable
                && (
                  <InputText
                    className={dropdownSearchInputCVA({ theme })}
                    prefix={{
                      icon: IconMagnifyingGlass,
                      useInputColor: !!searchTerm
                    }}
                    ghost={true}
                    size={size}
                    placeholder={typeof searchable === 'string' ? searchable : undefined}
                    suffix={
                      searchTerm.length > 0 ? {
                        as: 'button',
                        icon: IconXmarkSm,
                        onClick: clearSearch
                      } : undefined
                    }
                    value={searchTerm}
                    theme={theme}
                    onChange={handleChange}
                    ref={searchInputRef}
                  />
                )}
              <div className={cx('magnet-dropdown-list overflow-auto', { hiddenjf: resultNotFound })}>
                <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                  {children}
                </FloatingList>
              </div>
              <div className={cx(dropdownResultNotFoundCVA({ size, theme }), { hiddenjf: !resultNotFound })}>{noResultFoundText}</div>
            </div>
          </FloatingFocusManager>
        )}
      </DropdownContext.Provider>
    </>
  );
};

Dropdown.defaultProps = dropdownDefaultProps;
