import React, { useEffect, useRef } from 'react';
import { t } from '@jotforminc/translation';
import { bool, func } from 'prop-types';
import { IconRocketSquareFilled } from '@jotforminc/svg-icons';
import Tracking from '@jotforminc/tracking';
import {
  ALL_TEXTS, ASSET_IDENTIFIER, GA_FORM_TRACKING_ITEMS, userPropType,
  createGaAttributes, GA_SUFFIXES, renderModal,
  PrefilledLeadFormAside
} from '@jotforminc/enterprise-promotions-utils';
import '../../../styles/blogEducationSideBanner.scss';
import { EducationDiscountModal } from '../../Modals';

const BlogEducationSideBanner = ({
  user, logAbTestAction, isFormVisible, isTestVariant
}) => {
  const sectionRef = useRef();
  const { PRODUCT: { BLOG_EDUCATION_SIDE_BANNER } } = ASSET_IDENTIFIER;
  const target = BLOG_EDUCATION_SIDE_BANNER;
  const { [target]: gaFormTrackingItems } = GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesAn = createGaAttributes(target, GA_SUFFIXES.APPLY_NOW);
  const pageUrl = window.location.pathname;

  useEffect(() => {
    logAbTestAction({ action: 'seen', target });
    Tracking.enableFS({ epBlogBannerSetII_bool: true });
  }, []);

  const handleApplyNowClick = () => {
    renderModal({
      user,
      Modal: EducationDiscountModal,
      logAbTestAction,
      from: target,
      isTestVariant
    });
    logAbTestAction({ action: 'click', target: `contactSales-${target}` });
  };

  return (
    <div {...gaAttributes} ref={sectionRef} className='blog-education-side-banner'>
      <div className='banner-header'>
        <div className='banner-title'>
          <IconRocketSquareFilled className="title-icon" />
          {t(ALL_TEXTS.JOTFORM_ENTERPRISE_TITLE)}
        </div>
        <h5 className='banner-main-title'>{t(ALL_TEXTS.APPLY_ENTERPRISE_TO_GET_DISCOUNT_ED_TECH)}</h5>
        <div className='badge-wrapper'>
          <div className='badge'>
            <img
              src='https://cdn.jotfor.ms/assets/img/enterprise/blog-education-side-banner/ferpa.svg'
              alt={t(ALL_TEXTS.FERPA_COMPLIANCE)}
            />
          </div>
          <div className='badge'>
            <img
              src='https://cdn.jotfor.ms/assets/img/enterprise/blog-education-side-banner/hipaa.svg'
              alt={t(ALL_TEXTS.HIPAA_COMPLIANCE)}
            />
          </div>
          <div className='badge'>
            <img
              src='https://cdn.jotfor.ms/assets/img/enterprise/blog-education-side-banner/soc.svg'
              alt={t(ALL_TEXTS.SOC2_COMPLIANCE)}
            />
          </div>
          <div className='badge'>
            <img
              src='https://cdn.jotfor.ms/assets/img/enterprise/blog-education-side-banner/gdpr.svg'
              alt={t(ALL_TEXTS.GDPR_COMPLIANCE)}
            />
          </div>
          <div className='badge'>
            <img
              src='https://cdn.jotfor.ms/assets/img/enterprise/blog-education-side-banner/pci.svg'
              alt={t(ALL_TEXTS.PCI_COMPLIANCE)}
            />
          </div>
          <div className='badge'>
            <img
              src='https://cdn.jotfor.ms/assets/img/enterprise/blog-education-side-banner/ssl.svg'
              alt={t(ALL_TEXTS.BIT_256_SSL)}
            />
          </div>
        </div>

        {!isFormVisible && (
        <button
          {...gaAttributesAn}
          type='button'
          className='cta primary-cta'
          onClick={handleApplyNowClick}
        >
          {t(ALL_TEXTS.CONTACT_SALES)}
        </button>
        )}

      </div>
      {isFormVisible && (
        <div className='form-wrapper'>
          <PrefilledLeadFormAside
            user={user}
            gaAttributes={gaAttributes}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(ALL_TEXTS.SHORT_LEAD_FORM_TITLE)}
            formUiModifier={BLOG_EDUCATION_SIDE_BANNER}
            pageUrl={pageUrl}
            classNames='form-content'
          />
        </div>
      )}
    </div>
  );
};

BlogEducationSideBanner.propTypes = {
  user: userPropType.isRequired,
  logAbTestAction: func,
  isFormVisible: bool,
  isTestVariant: bool.isRequired
};

BlogEducationSideBanner.defaultProps = {
  logAbTestAction: f => f,
  isFormVisible: true
};

export default BlogEducationSideBanner;
