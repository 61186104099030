/* eslint-disable max-len */
export const ALL_TEXTS = {
  DASHBOARD: 'DASHBOARD',
  ADD_AND_MANAGE_USERS: 'ADD & MANAGE USERS',
  ADMIN_CONSOLE_CAPITALIZED: 'ADMIN CONSOLE',
  ENTERPRISE_ADMIN_CONSOLE: 'Enterprise Admin Console',
  ENHANCE_COLLABORATION: 'Enhance Collaboration',
  CLOSE_MODAL: 'Close modal',
  CLOSE: 'Close',
  ENTERPRISE: 'ENTERPRISE',
  COLLABORATOR_AVATARS: 'Collaborator avatars',
  ENTERPRISE_CAPITALIZED: 'Enterprise',
  CREATE_USER_WITH_CUSTOM_PERMISSONS: 'Create User or Admin accounts with custom permission settings to use Jotform with your whole team.',
  CREATE_USER_WITH_CUSTOM_PERMISSONS_ALT: '[1[Upgrade to Jotform Enterprise]] and create User or Admin accounts with custom permission settings to use Jotform with your whole team.',
  MANAGE_YOUR_COMPANY_FROM_ONE_LOC: 'Manage your company from one central location',
  UPGRADE_TO_ENTERPRISE_TO_USE_JOTFORM_WITH_YOUR_TEAM: 'Upgrade to Enterprise to use Jotform with your team',
  CONTROL_WHO_CAN_ACCESS_YOUR_FORMS: 'Control who can access your forms and submissions with Jotform Enterprise’s customizable permission settings. Add members of your team as Users or Admins to work together while you stay in full control of your data.',
  CONTACT_SALES: 'Contact Sales',
  UPGRADE: 'Upgrade',
  LEARN_MORE: 'Learn More',
  APPLY_NOW: 'Apply Now',
  DISMISS: 'Dismiss',
  LOADING: 'Loading...',
  USE_JOTFORM_FOR_YOUR_ENTIRE_ORG: 'Use Jotform for your entire organization',
  ASSIGN_AT_ONCE: 'Upgrade to Jotform Enterprise today to assign forms to your entire organization at once and',
  COLLABORATE_WITH_COWORKERS: 'Collaborate with your coworkers',
  CONTROL_ACCESS_TO: 'Control access to forms and submissions',
  MANAGE_ALL_OF_YOUR_FORMS: 'Manage all of your forms and data from the Admin Console',
  UPGRADE_TO_JOTFORM_ENTERPRISE_TO_MANAGE_USER_DATA: 'Upgrade to Jotform Enterprise to manage user data across your organization',
  QUICKLY_ACCESS_USER_HISTORY_AND_PROFILE: 'Quickly access user history and profile configurations to manage forms and form data overall.',
  MANAGE_USERS_AND_THEIR_ACCESS: 'Manage Users and Their Access',
  REVIEW_ACTIVITY_LOG: 'Review Activity Log',
  KEEP_TRACK_OF_USER_ACTIONS: 'Keep track of user actions with the activity log. See who has logged in and when, as well as who has created an app, form, and more.',
  TAKE_YOUR_BUSINESS_COLLAB_TO_NEXT_STEP: 'Take your business’s collaboration to the next level. Upgrade to Jotform Enterprise today to securely manage data and users from one place. Users can give each other access to forms and form data, while admins manage all forms, data, and responses.',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  ENTERPRISE_ROCKET: 'Jotform Enterprise rocket',
  // slider items
  VIEW_ALL_FORM_STATS: 'View all form stats from your dashboard. Jotform Enterprise administrators can seamlessly review stats for submissions, forms, and views. You can even filter your data by time range — daily, weekly, and monthly — or choose a specific time frame to pinpoint the exact data you’re searching for.',
  SECURELY_MANAGE_DATA: 'Securely manage data and users from one central account. All your Enterprise users are listed, ensuring quick access to user history and profile configuration. Users can give each other access to their forms and form data, while admins will always have access to shared forms and data.',
  MANAGE_ALL_AVAILABLE_FORMS: 'Manage all available forms on your Enterprise server from a single page. Select individuals or multiple forms to change form ownership or delete forms. You can also easily export a list of your forms into an Excel or CSV file in just one click.',
  KEEP_TRACK_OF_USER: 'Keep track of and search for a specific user’s form responses across your entire organization with Jotform Enterprise. Easily create reports of an individual’s submissions with options to review every submission or delete them all at once.',
  USE_ACTIVITY_LOG: 'Use the Activity Log to track new forms, form modifications and deletions, data purges, logins, and submission edits and deletions. You can also create a consolidated service activity log and export it as an Excel or CSV file.',
  GET_A_PROFFESSIONAL_CUSTOM_APP: 'Get a professional, custom branded app for your team. Submit your request from the Admin Console, and we’ll build an app just for you. From the Mobile App tab, you can also track users and devices logged, as well as invite your users to download your app.',
  NO_MATTER_DEDICATED_SUPPORT: 'No matter what you’re using Jotform Enterprise for, we’ve got your back. Our support team works tirelessly to respond to your concerns quickly. From this tab, you can create support tickets and track all support tickets sent from your account.',
  CUSTOMIZE_LOOK_AND_FEEL: 'Customize the look and feel of your Admin Console and forms without any coding or design knowledge. Upload your company logo, create custom themes, choose a favicon, and add your company’s name and domain address. Additionally, you can integrate your Jotform Enterprise account with an identity provider platform to enable SSO and IP address restrictions.',
  // enterprise billing banner
  ARE_YOU_LOOKING_FOR_ENTERPRISE_FEATURES: 'Are you looking for [1[enterprise level]] features?',
  GET_UNLIMITED_ACCESS_TO_THE_TOOLS_YOU_NEED: 'Get unlimited access to the tools you need to improve your online forms and data collection.',
  CHECKOUT_JOTFORM_ENTERPRISE: 'Check out Jotform Enterprise',
  // enterprise white labeling banner
  FULLY_CUSTOMIZE: 'Fully Customize with Enterprise',
  FULLY_INCORPORATE: 'Fully incorporate your brand into Jotform. Enjoy a personalized look without having to build your own product from scratch.',
  CUSTOMIZE_SSO: 'Customize our platform to match with your brand. Get custom domains and form URLs as well as single sign-on (SSO).',
  // enterprise goverment modal
  GOVERNMENT_SOLUTION_TITLE: 'Government Enterprise Solutions',
  GOVERNMENT_SOLUTION_SUB_TITLE: 'MORE POWER MORE SUPPORT',
  GOVERNMENT_SOC: 'SOC2, FERPA, HIPAA, PCI',
  GOVERNMENT_MULTIUSER: 'Multiuser Environment',
  GOVERNMENT_SSO: 'Single Sign On (SSO)',
  GOVERNMENT_ADMIN_DASHBOARD: 'Admin Dashboard',
  GOVERNMENT_UNLIMITED: 'Unlimited everything',
  // account box enterprise property modal
  GET_RID_OF_LIMITS: 'Get rid off all your limits!',
  CONGRATULATIONS: 'Congratulations!',
  EXCLUSIVE_ENTERPRISE_FEATURES: 'Start using all exclusive Enterprise features with your team.',
  YOU_MAY_CONSIDER_UPGRADING: 'You have received more than [1[{usagePhrase}]] so far! You may consider upgrading to [2[Jotform Enterprise]] and get rid of all your limits.',
  DEDICATED_SUPPORT_SUB_TITLE: 'Jotform Enterprise',
  DEDICATED_SUPPORT: 'Need Dedicated Support?',
  DEDICATED_SUPPORT_CONTENT: 'Get a dedicated support team with Jotform Enterprise.',

  JOTFORM_ENTERPRISE: 'Jotform Enterprise',
  JOTFORM_ENTERPRISE_CAPITALIZED: 'JOTFORM ENTERPRISE',
  COMPLETE_CUSTOMIZATION: 'Complete Customization with Enterprise',
  COMPLETE_CUSTOMIZATION_CONTENT: 'Create a seamless customer experience by white labeling your forms to showcase your company. Without coding, you can fully customize forms to match your branding, create custom form URLs, and even use your custom domain via Jotform Enterprise.',
  COMPLETE_CUSTOMIZATION_COMPANY_SETTINGS: 'Jotform Enterprise company settings',

  ACCESS_CONTROL: 'Stay in Full Control of Your Data',
  ACCESS_CONTROL_CONTENT: 'With a multiuser Jotform Enterprise plan, you can grant form access to multiple users. You’ll have full control over permission settings — add or delete users, filter your user list, send notifications, and review user activity for easy user management.',
  ACCESS_CONTROL_USERS_DASHBOARD: 'Jotform Enterprise user management dashboard',

  // webhook integration
  WEBHOOK_TITLE: 'White label your Jotform experience',
  WEBHOOK_CONTENT: 'Add your branding to all aspects of Jotform with Enterprise',

  // non profit lightbox
  FREE_WEBINAR_NONPROFIT_TITLE: 'Free Webinar for Nonprofits',
  FREE_WEBINAR_NONPROFIT_DATE: 'Thursday, February 9, 2023/11am PST',
  FREE_WEBINAR_NONPROFIT_DESCRIPTION: 'Increase Your Nonprofit’s Impact with the Right Technology',

  WEBINAR_NONPROFIT_DETAIL_TITLE: 'You’ll find out how to:',
  WEBINAR_NONPROFIT_DETAIL_IMPACT: 'Demonstrate impact',
  WEBINAR_NONPROFIT_DETAIL_COST: 'Control costs',
  WEBINAR_NONPROFIT_DETAIL_TRANSPARENCY: 'Improve transparency',
  WEBINAR_NONPROFIT_DETAIL_EXPLANATION: 'Join us to learn more and get additional valuable resources.',
  WEBINAR_NONPROFIT_FORM_TITLE: 'Please fill the related fields and get registered.',

  WEBINAR_NONPROFIT_SAVE_CTA: 'Save your seat!',
  WEBINAR_NONPROFIT_NOT_INTERESTED_CTA: 'Not interested',

  GET_CUSTOM_DOMAIN: '[1[Get custom domain with]] [2[Jotform Enterprise]]',

  ENTERPRISE_DO_YOU_WANT_DOMAIN_NAME: 'Do you want to use your own domain name?',
  WANT_TO_USE_YOUR_OWN_DOMAIN: 'Want to use your own domain name?',
  ENTERPRISE_TRY_JOTFORM: 'Try Jotform Enterprise!',
  APPLY_FOR_ENTERPRISE: 'Apply for Jotform Enterprise',
  CONTACT_SALES_REPRESENTATIVE: 'Contact Jotform Enterprise sales representative',

  APPLY_FOR_DEDICATED_ZOOM_SUPPORT: 'Apply for Enterprise to schedule Zoom calls with a dedicated support team.',
  LEARN_MORE_ABOUT_JOTFORM_ENTERPRISE: 'Learn more about Jotform Enterprise',
  CONTACT_ENTERPRISE_SALES_REPRESENTATIVE: 'Contact Enterprise sales representative',

  BOOST_COLLABORATION_WITH_ENTERPRISE: 'Boost collaboration with Enterprise',
  ADD_MULTIPLE_USERS: 'Add multiple users to work together with your team',

  HEALTHCARE_REPORT_TITLE: 'Get the latest insights on 2024 healthcare trends',
  HEALTHCARE_REPORT_GET_INSIGHTS: 'Get insights now!',
  HEALTHCARE_REPORT_DISMISS: 'Dismiss this banner',

  HEALTHCARE_REPORT_MODAL_MOBILE_TITLE: 'Get the latest insights on [1[2023]] healthcare trends.',
  HEALTHCARE_REPORT_MODAL_TITLE: 'Get the latest insights.',
  HEALTHCARE_REPORT_MODAL_CONTENT: 'Fill the form and get 2024 Healthcare Industry Trends report via email.',

  NEED_PROFESSIONAL_SOLUTIONS: 'Need enterprise level solutions?',
  NEED_PROFESSIONAL_SOLUTIONS_CONTENT: 'Try Jotform Enterprise with your team!',

  UPGRADE_TO_JOTFORM_ENTERPRISE_AND_START_MANAGING: 'Upgrade to [1[Jotform Enterprise]] and start managing your company from one central location.',

  CUSTOM_DOMAIN_API: 'Make your API url [1[your-domain.com/API]] or [2[subdomain.jotform.com/API]] instead of api.jotform.com with [3[Enterprise]].',

  ENTERPRISE_LEVEL_ANALYTICS_TITLE: 'Manage your company from one central location',
  ENTERPRISE_LEVEL_ANALYTICS_CONTENT: 'Manage user access, forms, and audit logs from a powerful Admin Console. Analyze data to reveal  important insights.',
  ENTERPRISE_LEVEL_ANALYTICS_GRAPH: 'Jotform Enterprise line graph',

  WHITE_LABEL_TITLE: 'Want more customization?',
  WHITE_LABEL_CONTENT: 'White label your experience with Jotform Enterprise. Add your branding, create custom URLs, and use your own domain.',
  WHITE_LABEL_LOGO_PLACEMENT_ANIMATION: 'Logo placement animation',

  JOTFORM_GOVERNMENT_TITLE: 'JOTFORM GOVERNMENT',
  JOTFORM_ENTERPRISE_TITLE: 'JOTFORM ENTERPRISE',
  AUSTRALIAN_DATA_RESIDENCY_MODAL_TITLE: 'Need to store data in [1[Australia]]? Contact our [1[Enterprise]] team to start complying with regulations.',
  HONG_KONG_DATA_RESIDENCY_MODAL_TITLE: 'Unlock advanced data security in Hong Kong',
  HONG_KONG_DATA_RESIDENCY_MODAL_DESCRIPTION: 'Explore enterprise solutions with enhanced security and compliance features tailored for Hong Kong-based organizations.',
  SINGAPORE_DATA_RESIDENCY_MODAL_TITLE: 'Unlock advanced data security in Singapore',
  SINGAPORE_DATA_RESIDENCY_MODAL_DESCRIPTION: 'Explore enterprise solutions with enhanced security and compliance features tailored for Singapore-based organizations.',

  LUNCH_LEARN_TITLE: 'LUNCH & LEARN SERIES',

  LUNCH_LEARN_CONTENT_SESSION_1: '[1[Next Session:]] How to white label',
  LUNCH_LEARN_CONTENT_SESSION_2: '[1[Next Session:]] How to manage your server as a server admin',
  LUNCH_LEARN_CONTENT_SESSION_3: '[1[Next Session:]] How to manage Teams',
  LUNCH_LEARN_CONTENT_SESSION_4: '[1[Next Session:]] How to use Jotform Sign',
  LUNCH_LEARN_CONTENT_SESSION_5: '[1[Next Session:]] How to configure single sign-on (SSO) and Prefill',
  LUNCH_LEARN_CONTENT_SESSION_6: '[1[Next Session:]] Intro to app building',

  LUNCH_LEARN_CLOSE: 'Close Lunch and Learn Banner',
  LUNCH_LEARN_SAVE_YOUR_SEAT: 'Save your seat!',

  STORE_DATA_IN_AUSTRALIA_CONTENT: 'Store your data only in Australia (Sydney) with [1[Jotform Enterprise]].',

  LUNCH_LEARN_MODAL_SUP_TITLE: 'COMING SOON',
  LUNCH_LEARN_MODAL_TITLE: 'Join Enterprise Lunch & Learn sessions',
  LUNCH_LEARN_MODAL_CONTENT: 'Join our customer success team to learn about forms, tables, approvals, and report basics; get guidance for server management and SSO configuration; or level up with conditional logic, prefill, payments, and more.',
  LUNCH_LEARN_MODAL_SAVE_SPOT: 'Save Your Spot!',
  LUNCH_LEARN_MODAL_LEARN_DETAILS: 'See details',
  LIVE: 'LIVE',
  JOIN_NOW: 'Join now!',
  NEVER_SHOW_THIS_CONTENT: 'Never show this content again.',

  IP_RESTRICTION_TITLE: 'Go pro with Jotform Enterprise',
  IP_RESTRICTION_CONTENT: 'Need to limit access? Switch to Jotform Enterprise for an extra layer of security.',
  IP_RESTRICTION_DISCOVER: 'Discover More',
  IP_WORLD: 'World map with IP pointer',

  COST_CUTTING_WEBINAR_BADGE: 'FREE',
  COST_CUTTING_WEBINAR_TITLE: 'Join our webinar',
  COST_CUTTING_WEBINAR_DATE: 'Tuesday, March 21, at 11 a.m. PT',
  COST_CUTTING_WEBINAR_DATE_SHORT: 'March 21, at 11 a.m. PT',
  COST_CUTTING_WEBINAR_EXPLANATION: '3 ways to cut costs from business processes',
  FREE_WEBINAR_BADGE: 'FREE WEBINAR',
  SAVE_SPOT_CTA: 'Save your spot!',
  COST_CUTTING_WEBINAR_FILL_MESSAGE: 'Please fill the related fields and get registered.',

  ADMIN_CONSOLE_DARK_MODAL_DESCRIPTION: 'Contact an [1[Enterprise]] sales representative to start managing your company from one central location',
  ADMIN_CONSOLE_DARK_MODAL_DESCRIPTION_V2: 'Get a free consultation for [1[Jotform Enterprise]] and start managing your company from one [2[central location]]',
  SHORT_LEAD_FORM_TITLE: 'JotForm Enterprise Sales Contact Request- Content Team - Short version',

  SAVE: 'save',
  DISCOUNT_PERCENTAGE: '[1[30]] [2[%]]',
  APPLY_ENTERPRISE_DISCOUNT: 'Apply for your Enterprise {discount_type} discount',

  GROUP_APPROVAL_BANNER_CONTENT: 'Upgrade to Jotform Enterprise to add more approvers',

  FIELD_SERVICE_WEBINAR_BADGE: 'FREE WEBINAR',
  FIELD_SERVICE_WEBINAR_EXPLANATION: '3 steps to safer, more sustainable field service operations',
  FIELD_SERVICE_WEBINAR_DATE: 'Tuesday, April 25, at 11 a.m. PT',

  HR_WEBINAR_BADGE: 'FREE HR WEBINAR',
  HR_WEBINAR_EXPLANATION: 'Less paperwork. More empowerment. HR automation that works.',
  HR_WEBINAR_DATE: 'Thursday, February 8, at 11 a.m. PT',

  HEALTHCARE_TECHNOLOGY_BADGE: 'FREE NON-PROFIT WEBINAR',
  HEALTHCARE_TECHNOLOGY_EXPLANATION: 'Surprising ways to delight donors with forms',
  HEALTHCARE_TECHNOLOGY_DATE: 'Thursday, March 21 at 11 a.m. PT',

  BOOK_CONSULTATION: 'Book Consultation',
  YOUR_INFO: 'YOUR INFO',
  CHOOSE_TIME: 'CHOOSE TIME',

  USERS_SECTION_TITLE: 'Contact an [1[Enterprise]] sales representative to add multiple users and manage them across your entire organization with powerful administrative features.',
  USERS_HEADER_ALT: 'Jotform Enterprise User Preview',
  USERS_FOOTER_ALT: 'Jotform Enterprise Admin and Regular User Preview',

  PRIVACY_CONCERN_WEBINAR_BADGE: 'FREE WEBINAR',
  PRIVACY_CONCERN_WEBINAR_EXPLANATION: 'How to manage customer data in an era of privacy concerns',
  PRIVACY_CONCERN_WEBINAR_DATE: 'Thursday, May 18, at 11 a.m. PT',

  GOVERNMENT_SOLUTION_TAILORED_TITLE: 'Get tailored solutions for your government agency',
  GOVERNMENT_LIST1: 'SOC 2, FERPA, HIPAA, PCI',
  GOVERNMENT_LIST1_WITHOUT_SOC2: 'FERPA, HIPAA, PCI',
  GOVERNMENT_LIST2: 'Multiuser environment',
  GOVERNMENT_LIST3: 'Single Sign-On (SSO)',
  GOVERNMENT_LIST4: 'Admin dashboard',
  GOVERNMENT_LIST5: 'Unlimited everything',

  GET_30_PERCENT_ED_TECH: 'Get [1[30% off]] powerful EdTech features with Jotform Enterprise',
  MULTIPLE_USERS: 'Multiple users',

  EARNED_BADGES_TITLE: 'Jotform received [1[500+]] software review awards',
  READ_THE_REPORT: 'Read the Report',

  EXPANDABLE_ADMIN_CONSOLE_CONTENT: 'Apply to [1[Jotform Enterprise]] to start managing your company from one central location',

  JOTFORM_TEAMS_MODAL_CONTENT: 'Contact an Enterprise sales representative to start using Jotform with your whole team',
  JOTFORM_TEAMS_MODAL_CONTENT_ALT: 'Get your whole team on board with Jotform Enterprise',
  ENTERPRISE_ONLY: 'ENTERPRISE ONLY',
  JOTFORM_TEAMS_MODAL_CONTENT_ALT_CLEAR: 'Upgrade to Enterprise to get your team on board',

  TEAM_LIMITATION_MODAL_PRE_CONTENT: 'You have reached your team creation limits.',
  TEAM_LIMITATION_MODAL_CONTENT: 'Contact an Enterprise sales representative to create unlimited teams for your organization.',

  APPLY_FOR_ENTERPRISE_ORGANIZATION: 'Contact an Enterprise sales representative to start using Jotform with your whole organization',
  CONTACT_FOR_TEAM_ON_BOARD: 'Contact an Enterprise sales representative to bring your team on board',

  PRICING_TABLE_MODAL_CONTENT: 'Send request to contact with Jotform representative',

  UNLIMITED_USAGE: 'Unlimited Usage',
  MULTIUSER_ENVIRONMENT: 'Multiuser Environment',
  SLA: 'Service Level Agreements (SLA)',
  HIPAA_COMPLIANCE_AVAILABLE: 'HIPAA Compliance Features Available',
  CUSTOM_DOMAIN: 'Custom Domain',
  PRIORITY_REAL_TIME_SUPPORT: 'Priority Real-Time Support',
  LOCAL_DATA_RESIDENCY_OPTION: 'Local Data Residency Option',
  SSO_INTEGRATIONS: 'Single Sign-On (SSO) Integrations',

  DISCOUNT: 'DISCOUNT',
  GET_DISCOUNT: 'GET DISCOUNT',

  APPLY_BRONZE_EDUCATION_DISCOUNT: 'Apply for Bronze plan education discount and save 50%',
  APPLY_SILVER_EDUCATION_DISCOUNT: 'Apply for Silver plan education discount and save 50%',
  APPLY_GOLD_EDUCATION_DISCOUNT: 'Apply for Gold plan education discount and save 50%',
  ENTERPRISE_DISCOUNT_EDUCATION_DESCRIPTION: 'Apply for Jotform Enterprise and get a 30% education discount',

  APPLY_BRONZE_NONPROFIT_DISCOUNT: 'Apply for Bronze plan nonprofit discount and save 50%',
  APPLY_SILVER_NONPROFIT_DISCOUNT: 'Apply for Silver plan nonprofit discount and save 50%',
  APPLY_GOLD_NONPROFIT_DISCOUNT: 'Apply for Gold plan nonprofit discount and save 50%',
  ENTERPRISE_DISCOUNT_NONPROFIT_DESCRIPTION: 'Apply for Jotform Enterprise and get a 30% nonprofit discount',

  BRONZE_PLAN: 'Bronze',
  SILVER_PLAN: 'Silver',
  GOLD_PLAN: 'Gold',

  WEBINAR_BADGE: 'FREE WEBINAR',

  AUTOMATE_ORGANIZATION_PROCESS: 'Automate your organization’s processes',
  APPLY_FOR_POWERFUL_AUTOMATION: 'Apply for Jotform Enterprise to get access to powerful automation features and a multiuser environment.',
  GET_YOUR_TEAM_ONBOARD: 'GET YOUR TEAM ON BOARD',
  GET_YOUR_TEAM_EXPLANATION: 'Apply to Jotform Enterprise and maximize your teamwork potential with Enterprise’s multiuser capabilities.',
  GET_YOUR_TEAM_EXPLANATION_V2: 'Maximize your teamwork with Jotform Enterprise’s multiuser features.',

  HEALTHCARE_WEBINAR_PROMOTION_MODAL_DESCRIPTION: 'Healthcare automation strategies for streamlined operations',
  HEALTHCARE_WEBINAR_PROMOTION_DATE: 'Thursday, July 20, at 11 a.m. PT',

  EDUCATION_REPORT_DOWNLOAD_TITLE: 'Fill out the form and get the educator’s technology toolkit via email',
  EDUCATION_REPORT_TITLE: 'Get the latest insights.',
  EDUCATION_REPORT_EXPLANATION: 'Fill out the form and get the educator’s technology toolkit via email.',

  LUNCH_LEARN: 'LUNCH & LEARN',
  LUNCH_LEARN_DESCRIPTION: 'Save your seat for upcoming Lunch & Learn sessions',

  ENTERPRISE_SECURITY_ITEM_SSO: 'Enable Single Sign-On.',
  ENTERPRISE_SECURITY_ITEM_SPECIFIED_IP: 'Allow access only to a specified IP.',
  ENTERPRISE_SECURITY_ITEM_AUTOMATIC_LOGOUT: 'Activate automatic logout.',

  SEGMENTED_MODAL_VIRGIN_ISLAND_ALT: 'Virgin Island Logo',
  SEGMENTED_MODAL_STAFFORDSHIRE_POLICE_ALT: 'Staffordshire Police Logo',
  SEGMENTED_MODAL_COUNTY_OF_MARIN_ALT: 'County of Marin Logo',
  SEGMENTED_MODAL_SAN_ANTONIO_ALT: 'San Antonio Logo',
  SEGMENTED_MODAL_BRUSSELS_ALT: 'Brussels Logo',
  SEGMENTED_MODAL_BERKELEY_ALT: 'Berkeley Logo',

  MAXIMUM_IMPACT_WEBINAR_DESCRIPTION: 'Maximum Impact: 5 ways to automate year-end giving',
  MAXIMUM_IMPACT_WEBINAR_DATE: 'Thursday, August 24, at 11 a.m. PT',

  HEALTHCARE_FUTURE_WEBINAR_DESCRIPTION: 'Powering healthcare’s future with form automation',
  HEALTHCARE_FUTURE_WEBINAR_DATE: 'Thursday, April 25, at 11 a.m. PT',

  BEFORE_YOU_GO: 'BEFORE YOU GO',
  ADV_TITLE_1: 'Request a free demo to learn more about the advanced features of Jotform Enterprise',

  ADV_BANNER_TITLE: 'Don\'t miss your chance to improve your business operations with Jotform Enterprise!',
  ADV_BANNER_DESCRIPTION: 'Get a free demo in which you will be told the Enterprise solutions that will benefit your business.',
  REQUEST_A_DEMO: 'Request a Demo',
  NOT_NOW: 'Not Now',

  FOMO_MODAL_BADGE: 'ACT FAST & SAVE',
  FOMO_TITLE: 'Jotform Enterprise Price Adjustment',
  FOMO_DATE: 'Act before October 1',
  FOMO_GREETING: 'On October 1st, Jotform Enterprise will have a price adjustment.Don’t miss your chance to save and lock in our current pricing.',
  FOMO_CONTENT: 'Contact the Enterprise Sales team today to sign up before October 1.',
  FOMO_THANK_YOU: 'Thank You',
  FOMO_THANK_YOU_SUB_TITLE: 'Our sales representative will get in touch with you shortly.',

  FORMS: 'Forms',
  MONTHLY_SUBMISSION: 'Monthly Submissions',
  SIGNED_DOCUMENTS: 'Signed Documents',
  SUBMISSION_STORAGE: 'Submission Storage',
  PAYMENT_SUBMISSIONS: 'Payment Submissions',
  API_AND_MORE: 'API [1[and more...]] ',
  MULTIUSER_PLATFORM: 'Multiuser Platform',
  MULTIPLE_TEAMS_AND_USERS: 'Multiple Teams and Users',
  DEDICATED_SUPPORT_LIST: 'Dedicated Support',
  USER_MANAGEMENT: 'User Management',
  USER_ACCESS_CONTROL: 'User Access Control',
  SINGLE_SIGN_ON: 'Single Sign-On',
  SINGLE_SIGN_ON_EXAMPLE: 'Microsoft, Google, Okta & more...',
  SLA_SHORT: 'SLA',
  SLA_EXPLANATION: 'Service Level Agreements',
  DATA_RESIDENCY: 'Data Residency',
  LOCAL_RESIDENCY: 'Local Residency & Dedicated Server',
  PROFESSIONAL_SERVICES: 'Professional Services',
  FORM_AND_WORKFLOW_SERVICES: 'Form & Workflow Building Services',
  HIPAA_COMPLIANCE_AVAILABLE_SHORT: 'HIPAA Compliance Features Available',
  CUSTOM_BRANDING_DOMAIN: 'Custom Branding & Domain',
  SHOW_ALL: 'Show all',

  BLOG_CONTENT_SPEED_UP_BANNER_TITLE: 'Speed up your workflows and improve efficiency with Jotform Enterprise',
  BLOG_CONTENT_SPEED_UP_BANNER_DIVIDER: 'OR FOR ADVANCED SOLUTIONS',
  BLOG_CONTENT_BOOST_BANNER_TITLE: 'Ready to boost your company’s productivity?',
  BLOG_CONTENT_SIDE_BANNER_TITLE: 'Need Jotform for your organization?',
  BLOG_CONTENT_SIDE_BANNER_DESCRIPTION: 'Contact us to book a demo and learn more about Enterprise-level features.',

  BLOG_CONTENT_BANNER_SIGN_UP_CTA: 'Sign Up',
  BLOG_CONTENT_BANNER_CUSTOM_QUOTE_CTA: 'Get Custom Quote',

  DEDICATED_SUPPORT_DESC: 'Apply to Jotform Enterprise to get access to a dedicated support team',
  DEDICATED_SUPPORT_DESC_ALT: 'Contact an Enteprise sales [1[representative to get access to a]] dedicated support team',

  FASTER_SUPPORT_RESP_BANNER_DESC: 'Get faster responses from a dedicated support team with Jotform Enterprise',
  PRIORITIZE_QUESTION: 'Turn On Faster Responses',

  FASTER_SUPPORT_RESP_MODAL_DESC: 'Apply for Enterprise to get faster responses from a dedicated support team',

  CONTACT_FOR_DEDICATED_SUPPORT_TEAM: 'Contact an Enterprise sales [1[representative to get access to a]] dedicated support team',

  CONTACT_ENTERPRISE: 'Contact Enterprise',
  SEE_PLAN_DETAILS: 'See plan details',
  CUSTOM_PRICING: 'Custom Pricing',
  USER_MANAGEMENT_AND_ACCESS_CONTROL: 'User Management & Access Control',
  LOCAL_DATA_RESIDENCY: 'Local Data Residency',
  CUSTOM_BRANDING: 'Custom Branding',
  ENTERPRISE_CONTACT_FORM: 'Enterprise Contact Form',
  EDUCATION_AND_NONPROFIT_DISCOUNT: '30% Off for Educators & Nonprofits',
  EDUCATION_AND_NONPROFIT_DISCOUNT_EXPLANATION: 'Request a quote to apply for a discount',
  TRUSTED_PLUS_TEXT: 'Trusted by 100+',
  LEADING_COMPANIES: 'Leading Companies',

  ED_TECH_WEBINAR_DESCRIPTION: 'EdTech tips to speed up administration and slash costs',
  ED_TECH_WEBINAR_DATE: 'Thursday, September 28, at 11 a.m. PT',

  SURE_TO_GO_BACK: 'Are you sure you want to go back to the pricing page?',
  GO_TO_PRICING: 'Go to Pricing',
  STAY: 'Stay',
  REQUEST_RECEIVED: 'Request received',
  THANKS_FOR_GETTING_TOUCH: 'Thanks for getting in touch. Our sales team will respond to you shortly.',

  BLOG_BANNER_MODAL_TITLE: 'Contact us to book a demo & learn more about Enterprise-level features',

  GET_HANDS_ON_PROFESSIONAL_SERVICES: 'Get hands-on help from Jotform Enterprise Professional Services',
  GET_HANDS_ON_PROFESSIONAL_SERVICES_DESCRIPTION: 'Fast-track onboarding, implementation, and more with a team committed to your success.',
  GET_A_QUOTE: 'Get a Quote',
  SESSIONS_BANNER_TEXT: '[1[Add multiple users to your account with Jotform Enterprise!]] You can add or delete users, review user activity.',

  SSO_ACCESS_TITLE: 'Access forms using Single Sign-On (SSO)',
  SSO_ACCESS_DESCRIPTION: 'Use a single set of login credentials to access and manage your forms with Jotform Enterprise.',

  SSO_PREFILL_TITLE: 'Single Sign-On Prefill',
  SSO_PREFILL_DESCRIPTION: 'Jotform Enterprise offers single sign-on configuration. With this configuration you can pre-populate your form fields with data from SSO provider.',

  APPLY_ENTERPRISE_FOR_SSO_PREFILL: 'Apply for Enterprise to start using Single Sign-On (SSO) prefill',
  CONTACT_ENTERPRISE_SALES_SSO_PREFILL: 'Contact an Enterprise sales representative to enable Single Sign-On (SSO) Prefill',

  WITHOUT_CODING_FULLY_CUSTOMIZE_JOTFORM: 'Without coding, you can fully customize Jotform to match your branding. Get custom domains and form URLs as well as single sign-on (SSO).',
  WANT_TO_CUSTOMIZE_WORKFLOW: 'Want to customize workflow pages with your company colors and logo?',

  NHS_TITLE: 'Elevate Data Security, Elevate Trust',
  NHS_DESCRIPTION: 'Unlock tailored enterprise solutions for your trust, backed by top-tier security compliance, all within UK borders.',
  SCHEDULE_MEETING: 'Schedule a Meeting',

  SCHEDULE: 'Schedule',
  HEY_THERE: 'Hi there,',
  EXCLUSIVE_OFFER_FOR_YOU: 'Exclusive offer for you!',
  CHOOSE_MEETING_TIME_EDUCATION: 'Choose a 10-minute time slot to chat with me. I\'d love to help you get the most out of Jotform Enterprise\'s EdTech features and share my experience.',
  CONTACT_WITH_ME_UNLOCK_EDUCATION: 'Contact me to unlock a 30% discount on our Enterprise plan tailored for the education industry. Let’s discuss how our EdTech features can supercharge your organization.',
  BEST: 'Best,',
  AUSTIN_FOLEY: 'Austin Foley',
  EDUCATION_VERTICAL_SPECIALIST: 'Education Vertical Specialist',
  CONTACT_NOW: 'Contact Now',

  APPLY_FULLY_BRANDED_EXPERIENCE: 'Apply for Jotform Enterprise to build a fully branded experience',
  CONTACT_SALES_REPRESENTATIVE_BRANDED_EXPERIENCE: 'Contact an Enterprise sales representative to use your own domain name',

  APPLY_ENTERPRISE_TO_GET_DISCOUNT_ED_TECH: 'Contact Enterprise sales representative to get 30% off powerful EdTech features',
  APPLY_ENTERPRISE_TO_GET_DISCOUNT_ED_TECH_LINED: 'Contact Enterprise sales representative to get 30% off powerful EdTech features',
  FERPA_COMPLIANCE: 'FERPA Compliance Badge',
  HIPAA_COMPLIANCE: 'HIPAA Compliance Badge',
  SOC2_COMPLIANCE: 'SOC2 Compliance Badge',
  GDPR_COMPLIANCE: 'GDPR Compliance Badge',
  PCI_COMPLIANCE: 'PCI Compliance Badge',
  BIT_256_SSL: '256 Bit SSL Encryption Badge',

  EDUCATION_DISCOUNT_PERCENT: '30% Education Discount',

  GET_HANDS_ON_HELP: 'Get hands-on help with setup, training, and more',
  TAKE_ADVANTAGE_OF_EXPERT_LED_BUILD: 'Take advantage of expert-led builds and training to optimize your workflow and empower your team. Apply for Jotform Enterprise Professional Services today.',

  APPLY_ENTERPRISE_TO_GET_PROFESSIONAL_SERVICES: 'Apply for Enterprise to get [1[professional services]]',

  PERCENT_30: '30%',
  PERCENT_30_OFF: '30% off',
  JOIN_AND_GET_30_PERCENT_EDU_DISCOUNT: 'Join and get 30% Education Discount',

  APPLY_ENTERPRISE_TO_GET_LIVE_ZOOM_SUPPORT: '[1[Apply for Enterprise to get]] live Zoom support',
  ZOOM_MEETING_VISUAL_DESCRIPTION: 'Enterprise user and Jotform Enterprise Representative Zoom Meeting',
  SCHEDULE_ZOOM_MEETING: 'Schedule Zoom Meeting',

  MORE_POWER_MORE_SUPPORT: 'More power. More support.',
  JOTFORM_ENTERPRISE_SSO_FEATURE: 'If you would like to use Require SSO feature please check out Jotform Enterprise.',

  JOTFORM_ENTERPRISE_OFFERS_SSO: 'Jotform Enterprise offers single sign-on configuration. With this configuration you can pre-populate your form fields with data from SSO provider.',
  FULL_NAME: 'Full Name',
  PHONE_NUMBER: 'Phone Number',
  JOB_TITLE: 'Job Title',
  UPGRADE_TO_ENTERPRISE: 'Upgrade to Enterprise',
  UPGRADE_TO_JOTFORM_ENTERPRISE: 'Upgrade to Jotform Enterprise',

  TOP_NOTCH_SECURITY: 'Top-Notch Security',
  HOST_YOUR_DATA_IN_ANY_PART_OF_THE_WORLD: 'Host your data anywhere in the world with local data residency. Your data is protected by 256-bit SSL, PCI DSS Level 1 certification, and the option to enable HIPAA compliance features.',
  LOCAL_DATA_RESIDENCY_BANNER: 'Local data residency banner',

  SSO_PREFILL: 'SSO PREFILL',
  SSO_PREFILL_APPLY_TITLE: 'Apply for Jotform Enterprise to enable single sign-on prefill',
  SSO_PREFILL_PROVIDER_DESCRIPTION: 'Jotform Enterprise offers single sign-on configuration which you can pre-populate your form fields with data from SSO provider.',

  APPLY_ENTERPRISE_TO_HOST_ANYWHERE: 'Apply for Enterprise to host your data anywhere in the world',
  DATA_PROTECTION_SSL_PCI_DSS: 'Protect your data with 256-bit SSL, PCI DSS Level 1 certification, and the option to enable HIPAA compliance features.',

  REACHED_FOLDER_LIMIT: 'You’ve reached your [1[folder limit]]',
  APPLY_FOR_ENTERPRISE_FOLDER: 'Contact an Enterprise sales representative to create unlimited folders and expertly organize your forms [1[and data.]]',

  PLEASE_SUBMIT_EMAIL: 'To get access to this content, please submit your email.',

  GET_REAL_TIME_SUPPORT: 'Apply for Enterprise to get real-time support',
  APPLY_ENTERPRISE_TO_GET_REAL_TIME_SUPPORT: 'Apply for Enterprise to get real-time support',

  FQHC_TITLE: 'Request a meeting with an Enterprise expert with insights into how Jotform Enterprise helps FQHC',

  DEDICATED_VIDEO_CHAT: 'Get dedicated video chat support',
  DEDICATED_VIDEO_CHAT_MODAL_DESC: 'Apply for Enterprise to get dedicated video chat support',

  APPLY_ENTERPRISE_FOR_SPECIFIC_LOCATION: '[1[Contact an Enterprise sales]] representative to store your data in a specific location',

  ENTERPRISE_DATA_CENTERS: 'Enterprise Data Centers',
  STORE_DATA_IN_SPECIFIC_LOCATION: 'Store my data in a specific location',
  SELECT_DATA_CENTER: 'Select data center',
  SEARCH: 'Search',

  THIS_FEATURE_AVAILABLE_FOR_ENTERPRISE: 'This feature is available only with [1[Jotform Enterprise]]',

  ENTERPRISE_FEATURE: 'Enterprise feature',

  APPLY_ENTERPRISE_TO_ENABLE_SSO: 'Apply for Enterprise to enable Single Sign-On (SSO)',
  SSO_MODAL_VISUAL_DESCRIPTION: 'SSO Login Preview',

  GET_ACCESS_TO_SYSTEM_LOGS: 'Get access to system logs for powerful Enterprise reporting',
  TRACK_ALL_OUTGOING_MESSAGES: 'Track all outgoing emails and integration actions for your Enterprise team.',
  TRACK_ALL_OUTGOING_EMAILS: '[1[Contact an Enterprise sales representative]] to track all outgoing emails with system logs',
  APPLY_ENTERPRISE_TO_TRACK_ALL_OUTGOING_EMAILS: 'Apply for Enterprise to track all outgoing emails and integration actions',

  APPLY_ENTERPRISE_TO_ENABLE_SSO_PREFILL: 'Apply for Enterprise to enable Single Sign-On (SSO) Prefill',
  APPLY_ENTERPRISE_TO_ENABLE_SSO_PREFILL_DESCRIPTION: 'Jotform Enterprise lets you pre-populate fields using data from your SSO provider. You’ll also get unlimited forms, submissions, and more!',
  SSO_PREFILL_VISUAL_EXPLANATION: 'Single Sign-On (SSO) Prefill Preview',

  YOUR_DOMAIN: 'https://www.[1[yourdomain]].ext/login',
  YOUR_LOGO: 'Your Logo',

  GET_THE_APP: 'Get the App',
  ITS_FREE: 'It\'s Free!',
  JOTFORM_IS_ON_APP_EXCHANGE: 'Jotform is now on Salesforce AppExchange!',
  USE_JOTFORM_WITHOUT_LEAVING_SALESFORCE: 'Use Jotform without leaving Salesforce',
  CONTACT_SALES_TO_USE_JOTFORM_WITHOUT_LEAVING_SALESFORCE: 'Start using Jotform right from your [1[Salesforce dashboard]]',
  CONTACT_OUR_SALES_TEAM_TO_USE_JOTFORM_IN_SALESFORCE: 'Contact our sales team to use Jotform in Salesforce',
  APPEXCHANGE_MODAL_VISUAL_DESCRIPTION: 'Jotform and Salesforce Icons',

  THANK_YOU_FOR_DOWNLOADING: 'Thank you for downloading',
  THANK_YOU_FOR_DOWNLOADING_EXPLANATION: 'Request a free demo to learn more about the advanced features of Jotform Enterprise',

  DOWNLOAD_JOTFORM_ENTERPRISE_FOR_BEGINNERS: 'Download Jotform Enterprise for Beginners',
  DOWNLOAD_JOTFORM_ENTERPRISE_FOR_BEGINNERS_EXPLANATION: 'Please fill out the form below to download the book ',

  NEED_PROFESSIONAL_SERVICE: 'Need professional services? Upgrade to Jotform Enterprise',
  DISCOVER_NOW: 'Discover Now',

  DEDICATED_SUPPORT_SECTION_DESCRIPTION: 'Get a dedicated support team with Jotform Enterprise.',
  DEDICATED_SUPPORT_BANNER_DESCRIPTION: 'Want real-time support? Get dedicated support team with Jotform Enterprise.',
  GET_NOW: 'Get Now',
  APPLY_TO_JOTFORM_ENTERPRISE_PLAN: 'Apply to Jotform Enterprise Plan',

  CONTACT_SALES_REPRESENTATIVE_DEDICATED_SUPPORT: 'Contact an Enterprise Sales representative to get access to a dedicated support team',

  SCHEDULE_ZOOM_CALL: 'Schedule a Zoom Call',

  GET_PDF: 'GET PDF'

};
