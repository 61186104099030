const featuredIntegrationsLinks = [
  {
    name: 'featuredMicrosoftTeams',
    title: 'Microsoft Teams',
    url: '/integrations/microsoft-teams',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/microsoft-teams.svg'
  },
  {
    name: 'featuredPaypal',
    title: 'PayPal',
    url: '/integrations/paypal-business',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/paypal.svg'
  },
  {
    name: 'featuredSlack',
    title: 'Slack',
    url: '/integrations/slack',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/slack.svg'
  },
  {
    name: 'featuredGoogleSheets',
    title: 'Google Sheets',
    url: '/integrations/google-sheets',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-sheets.svg'
  },
  {
    name: 'featuredMailchimp',
    title: 'Mailchimp',
    url: '/integrations/mailchimp',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/mailchimp.svg'
  },
  {
    name: 'featuredZoom',
    title: 'Zoom',
    url: '/integrations/zoom',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/zoom.svg'
  },
  {
    name: 'featuredDropbox',
    title: 'Dropbox',
    url: '/integrations/dropbox',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/dropbox.svg'
  },
  {
    name: 'featuredGoogleCalendar',
    title: 'Google Calendar',
    url: '/integrations/google-calendar',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-calendar.svg'
  },
  {
    name: 'featuredSalesforce',
    title: 'Salesforce',
    url: '/integrations/salesforce',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/salesforce.svg'
  }
];

export default featuredIntegrationsLinks;
