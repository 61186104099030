import { useEffect } from 'react';
import {
  initEpEttGatedContentModal, initEpHealthcareReportModal, initEpProfessionalServices, initEpFerpaBanner, initEpCaseStudyAssets, initEpAdLeadFlowBlogPosts
} from '../enterprisePromotions';
import { initEnterpriseJobs } from '../utils';

const useEnterprisePromotions = user => {
  useEffect(() => {
    if (!user) return;

    initEpEttGatedContentModal(user);
    initEpHealthcareReportModal(user);
    initEpProfessionalServices();

    initEpFerpaBanner(user);
    initEpCaseStudyAssets(user);
    initEpAdLeadFlowBlogPosts(user);
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        await initEnterpriseJobs();
      } catch (error) {
        console.log(`Error on initializing epJobs: ${error}`);
      }
    })();
  }, []);
};

export default useEnterprisePromotions;
