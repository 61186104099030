import { LoginFlowHelper } from '@jotforminc/login-flow';
import { getBodyEl } from '../dom';
import { loadAndInsertAsFirstScript } from '../scriptLoaders';

export const loadLoginFlow = onLoad => {
  if (global.window.loginFlowHelper) {
    if (onLoad) onLoad(global.window.loginFlowHelper);
    return Promise.resolve(global.window.loginFlowHelper);
  }

  return Promise.resolve()
    .then(() => {
      const loginFlowHelper = new LoginFlowHelper();
      global.window.loginFlowHelper = loginFlowHelper;
      return loginFlowHelper;
    })
    .then(loginFlowHelper => {
      if (onLoad) onLoad(loginFlowHelper);
      return loginFlowHelper;
    });
};

const getSocialMediaScriptInfo = socialMedia => {
  switch (socialMedia) {
    case 'facebook':
      return { id: 'facebook-jssdk', src: 'https://connect.facebook.net/en_US/sdk.js' };
    case 'google':
      return { id: 'google-jssdk-new', src: 'https://accounts.google.com/gsi/client' };
    case 'microsoft':
      return { id: 'microsoft-jssdk', src: 'https://cdn.jotfor.ms/js/msal/msal-browser.js' };
    default:
      throw new Error(`Unknown social media: ${socialMedia}`);
  }
};

export const loadSocialMediaClient = socialMedia => {
  return new Promise((resolve, reject) => {
    try {
      const bodyEl = document.querySelector('body');

      if (bodyEl.classList.contains(`isloaded-${socialMedia}js`)) {
        return resolve();
      }

      const { id, src } = getSocialMediaScriptInfo(socialMedia);

      loadAndInsertAsFirstScript(id, src, () => {
        bodyEl.classList.add(`isloaded-${socialMedia}js`);
        resolve();
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const loadGoogleJS = () => {
  return getBodyEl().classList.contains('isloaded-googlejs') ? Promise.resolve() : loadSocialMediaClient('google');
};

export const loadFacebookJS = () => {
  return getBodyEl().classList.contains('isloaded-facebookjs') ? Promise.resolve() : loadSocialMediaClient('facebook');
};

export const loadMicrosotfJS = () => {
  return getBodyEl().classList.contains('isloaded-microsoftjs') ? Promise.resolve() : loadSocialMediaClient('microsoft');
};
