import { t } from '@jotforminc/translation';
import { TEXTS } from '../constants';

export const enterpriseNavItem = {
  name: 'enterprise',
  linkProps: {
    url: '/enterprise/?utm_medium=referral&utm_source=jotform.com&utm_content=Jotform_Enterprise_Header&utm_campaign=enterprise_common_header',
    title: t(TEXTS.ENTERPRISE)
  }
};
