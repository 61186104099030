import React from 'react';
import cx from 'classnames';
import { array, bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconChevronRight } from '@jotforminc/svg-icons';

const SignMenuType = ({ menuItems, isOpen }) => {
  const SignMenuTypeClasses = cx('jfRHeader--nav-sub-menu jfSign', {
    isOpen
  });

  return (
    <>
      {menuItems.length > 0 && (
        <ul className={SignMenuTypeClasses}>
          <li className="jfRHeader--nav-sub-menu-left-banner">
            <a href="/products/sign/">
              <img className="jfh-sign-logo" src="https://www.jotform.com/pdf-templates/assets/img/sign-logo-dark.svg?v=0.1" alt="Jotform Sign Logo" />
              <p class="locale">
                {t('Create, share, and e-sign documents in minutes using Jotform Sign.')}
              </p>
              <img
                src="https://cdn01.jotfor.ms/assets/img/jfHeader/v2/sign-banner.png?v=0.1"
                className="jfh-sign-banner integrationImage"
                alt="Jotform Sign Banner"
              />
            </a>
          </li>
          {menuItems.map(item => (
            <li key={item.name} className="jfRHeader--nav-sub-menu-item">
              <span className={`jfRHeader--nav-sub-menu-text locale ${item.title === '' ? 'blank-row' : ''}`}>{t(item.title)}</span>
              {item.subMenuItems && (
                <ul>
                  {item.subMenuItems.map(({ name, title, url }) => (
                    <li key={name} className="jfRHeader--nav-sub-menu-item">
                      <a href={url} className="locale">{t(title)}</a>
                    </li>
                  ))}
                  {item.seeMore && (
                    <li className='jfRHeader--nav-sub-menu-item'>
                      <a href={item.seeMore.url} className="jfRHeader--nav-sub-menu-item-link moreLink">
                        <span className="locale">{t(item.seeMore.title)}</span>
                        <IconChevronRight />
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

SignMenuType.propTypes = {
  menuItems: array.isRequired,
  isOpen: bool.isRequired
};

export default SignMenuType;
