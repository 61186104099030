import React, { useState, useContext, createContext } from 'react';
import { node, object, string } from 'prop-types';

const AssetManagerContext = createContext({});

export const AssetManagerProvider = ({
  children,
  campaignInfo,
  assetInfo,
  assetType
}) => {
  const [state] = useState({
    campaignInfo,
    assetInfo,
    assetType
  });
  return (
    <AssetManagerContext.Provider
      value={{
        state
      }}
    >
      { children }
    </AssetManagerContext.Provider>
  );
};

AssetManagerProvider.propTypes = {
  children: node.isRequired,
  campaignInfo: object.isRequired,
  assetInfo: object.isRequired,
  assetType: string.isRequired
};

export const useAssetManagerContext = () => {
  const { state } = useContext(AssetManagerContext);

  return {
    ...state
  };
};
