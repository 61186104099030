/* global HTMLElement */
import React from 'react';
import ReactDOM from 'react-dom';
import { getRootElement } from '@jotforminc/enterprise-promotions-utils';
import { UserGuideProfessionalServicesBanner, GeneralFaqProfessionalServicesBanner } from '../components';

const renderProfServicesAsset = ({ assetName, rootElementId, ...props }) => {
  const rootEl = getRootElement(rootElementId);

  if (!(rootEl instanceof HTMLElement)) return;

  const assetMap = {
    GeneralFaqProfessionalServicesBanner,
    UserGuideProfessionalServicesBanner
  };

  const SelectedAsset = assetMap[assetName];

  ReactDOM.render(
    <SelectedAsset {...props} />,
    rootEl
  );
};

export default renderProfServicesAsset;
