import { t } from '@jotforminc/translation';
import { TEXTS } from '../constants';

export const pricingsNavItem = {
  name: 'pricing',
  linkProps: {
    url: '/pricing',
    title: t(TEXTS.PRICING),
    isPricingLink: true
  }
};
