import React, { useEffect, useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import { func, object } from 'prop-types';
import { t } from '@jotforminc/translation';
import {
  ALL_TEXTS, healthcareReportModalSeenAtUserSettingsKey, ASSET_IDENTIFIER, userPropType, GA_FORM_TRACKING_ITEMS,
  setEpUserSettings, createGaAttributes, GA_SUFFIXES, gaDataLayerPushClose, setEPActions, mapCallbacksToGaTrackingItems,
  PrefilledLeadFormAside
} from '@jotforminc/enterprise-promotions-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import '../../../../styles/healthcareReportModal.scss';
import '../../../../styles/dialogShared.scss';

const HealthcareReportModal = ({
  user,
  modalProps,
  onClose
}) => {
  const uikitModalRef = useRef(null);
  const { CAMPAIGN: { HEALTHCARE_REPORT_MODAL: target } } = ASSET_IDENTIFIER;
  let { [target]: gaFormTrackingItems } = GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    setEpUserSettings(healthcareReportModalSeenAtUserSettingsKey);
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleCloseModalClick = () => {
    uikitModalRef.current?.hide();
    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);
    setEPActions({ asset: target, target: 'onClose', action: 'click' });
    onClose();
  };

  const handleGetInsightsNowClick = () => {
    setEPActions({ asset: target, target: 'getInsightsNowButton', action: 'click' });
  };

  const handleFormSubmit = () => {
    setEPActions({ asset: target, target, action: 'formSubmit' });
    // saveCustomerAsHubspotFormByUsername ({ lastProductAssetInteraction: sdrSource });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleGetInsightsNowClick]);

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={false}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseModalClick} />}
      onModalClose={handleClose}
      {...modalProps}
    >
      <div className='left-col'>
        <div className='left-col-header'>
          <img src="//cdn.jotfor.ms/assets/img/enterprise/healthcare-report/healthcare-icon.png" alt={t(ALL_TEXTS.HEALTHCARE_REPORT_MODAL_TITLE)} className="icon" />
          <div className='title-wrapper'>
            <h3 className="healthcare-report-modal-main-title">{t(ALL_TEXTS.HEALTHCARE_REPORT_MODAL_TITLE)}</h3>
            <p className="healthcare-report-modal-message">{t(ALL_TEXTS.HEALTHCARE_REPORT_MODAL_CONTENT)}</p>
          </div>
        </div>
        <PrefilledLeadFormAside
          user={user}
          formID='240733421066954'
          formUiModifier='downloadModal'
          gaAttributes={gaAttributes}
          gaFormTrackingItems={gaFormTrackingItems}
          title={t(ALL_TEXTS.HEALTHCARE_REPORT_MODAL_TITLE)}
          onFormSubmit={handleFormSubmit}
        />
      </div>
      <div className='right-col'>
        <div className='visual-wrapper'>
          <img src="//cdn.jotfor.ms/assets/img/enterprise/healthcare-report/report-cover-2024.png?v=0.0.2" alt={t(ALL_TEXTS.HEALTHCARE_REPORT_MODAL_TITLE)} className="cover" />
        </div>
      </div>
    </Modal>
  );
};

HealthcareReportModal.propTypes = {
  user: userPropType.isRequired,
  modalProps: object,
  onClose: func
};

HealthcareReportModal.defaultProps = {
  modalProps: {},
  onClose: f => f
};

export default HealthcareReportModal;
