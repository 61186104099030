const storageLinks = [
  {
    name: 'googleDrive',
    title: 'Google Drive',
    url: '/integrations/google-drive',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/google-drive.svg'
  },
  {
    name: 'dropbox',
    title: 'Dropbox',
    url: '/integrations/dropbox',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/dropbox.svg'
  },
  {
    name: 'oneDrive',
    title: 'OneDrive',
    url: '/integrations/onedrive',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/onedrive.svg'
  },
  {
    name: 'box',
    title: 'Box',
    url: '/integrations/box',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/box.svg'
  },
  {
    name: 'egnyte',
    title: 'Egnyte',
    url: '/integrations/egnyte',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/egnyte.svg'
  },
  {
    name: 'ftp',
    title: 'FTP',
    url: '/integrations/ftp',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/ftp.png'
  },
  {
    name: 'galleria',
    title: 'Galleria',
    url: '/integrations/galleria',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/integrations/galleria.png'
  }
];

export default storageLinks;
