import { t } from '@jotforminc/translation';
import cx from 'classnames';
import { bool, func, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Hooks } from '@jotforminc/uikit';
import { StorageHelper } from '@jotforminc/storage-helper';
import {
  ALL_TEXTS, ASSET_IDENTIFIER, leadFlowBannerAutoVisibleLcStKey, leadFlowBannerUrlParam, userPropType,
  GA_SUFFIXES, createGaAttributes, renderModal
} from '@jotforminc/enterprise-promotions-utils';
import { AdBannerModal, AdFirstExitIntentModal, AdSecondExitIntentModal } from '../../Modals';
import '../../../styles/advertisementLeadFlowBanner.scss';
import RocketIcon from '../../../assets/svg/advertisementLeadFlowBanner/rocket.svg';
import MainVisual from '../../../assets/svg/advertisementLeadFlowBanner/visual.svg';

const AdvertisementLeadFlowBanner = ({
  user, logAbTestAction, isExitIntentModalAvailable, seenAtKey
}) => {
  const toggleRef = useRef();
  const bannerRef = useRef();
  const TIME_TO_BANNER_VISIBLE = 15_000;

  const [isVisible, setIsVisible] = Hooks.useClickOutsideState(false, [toggleRef, bannerRef]);

  const onceAutoVisibled = useRef(false);
  const onceBannerOpened = useRef(false);
  const onceModalShown = useRef(false);

  const { AB_TEST: { ADVERTISEMENT_LEAD_FLOW_BANNER: target } } = ASSET_IDENTIFIER;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesRad = createGaAttributes(target, GA_SUFFIXES.REQUEST_A_DEMO);
  const gaAttributesNn = createGaAttributes(target, GA_SUFFIXES.NOT_NOW);

  useEffect(() => {
    logAbTestAction({ action: 'seen', target });

    const className = 'ad-lead-flow-banner-visible';
    const bodyEl = document.querySelector('body');
    if (!bodyEl.classList.contains(className)) {
      bodyEl.classList.add(className);
    }
    return () => {
      bodyEl.classList.remove(className);
    };
  }, []);

  useEffect(() => {
    const isAutoVisibleSeen = StorageHelper.getLocalStorageItem({ key: leadFlowBannerAutoVisibleLcStKey });

    const timer = setTimeout(() => {
      if (isVisible || onceBannerOpened.current || onceModalShown.current || isAutoVisibleSeen) return;
      setIsVisible(true);
      onceAutoVisibled.current = true;
      onceBannerOpened.current = true;

      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.get(leadFlowBannerUrlParam)) {
        StorageHelper.setLocalStorageItem({
          key: leadFlowBannerAutoVisibleLcStKey, value: '1'
        });
      }
    }, TIME_TO_BANNER_VISIBLE);

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);

  // listen exit intent events
  useEffect(() => {
    const handleMouseLeave = () => {
      if (onceModalShown.current || !isExitIntentModalAvailable) return;
      if (onceAutoVisibled.current) {
        renderModal({
          Modal: AdSecondExitIntentModal, logAbTestAction, user, seenAtKey
        });
      } else {
        renderModal({
          Modal: AdFirstExitIntentModal, logAbTestAction, user, seenAtKey
        });
      }
      onceModalShown.current = true;
    };
    document.body.addEventListener('mouseleave', handleMouseLeave);
  }, []);

  const handleToggleClick = () => {
    setIsVisible(visible => !visible);
    onceBannerOpened.current = true;
    logAbTestAction({ action: 'click', target: `toggleButton-${target}` });
  };

  const handleRequestADemo = () => {
    renderModal({ Modal: AdBannerModal, logAbTestAction, user });
    onceModalShown.current = true;
    logAbTestAction({ action: 'click', target: `getADemoButton-${target}` });
  };

  const handleNotNowClick = () => {
    setIsVisible(false);
    logAbTestAction({ action: 'click', target: `notNowButton-${target}` });
  };

  const bannerClasses = cx({
    'banner-wrapper': true,
    show: isVisible
  });

  return (
    <div className='advertisement-visitor-banner' {...gaAttributes}>
      {/* toggle */}
      <button
        type="button"
        ref={toggleRef}
        onClick={handleToggleClick}
        className="initial-cta"
        aria-label={t(ALL_TEXTS.ADV_BANNER_TITLE)}
      >
        <RocketIcon />
      </button>

      {/* banner */}
      <div className={bannerClasses} ref={bannerRef}>
        <MainVisual className='visual' />
        <div className='content-wrapper'>
          <h2 className='title'>{t(ALL_TEXTS.ADV_BANNER_TITLE)}</h2>
        </div>
        <div className='button-wrapper'>
          {/* request a demo */}
          <button
            type='button'
            onClick={handleRequestADemo}
            className='cta primary-cta'
            aria-label={t(ALL_TEXTS.REQUEST_A_DEMO)}
            {...gaAttributesRad}
          >
            {t(ALL_TEXTS.REQUEST_A_DEMO)}
          </button>
          {/* not now */}
          <button
            type='button'
            onClick={handleNotNowClick}
            className='cta secondary-cta'
            aria-label={t(ALL_TEXTS.NOT_NOW)}
            {...gaAttributesNn}
          >
            {t(ALL_TEXTS.NOT_NOW)}
          </button>
        </div>
      </div>
    </div>
  );
};

AdvertisementLeadFlowBanner.propTypes = {
  isExitIntentModalAvailable: bool,
  user: userPropType.isRequired,
  logAbTestAction: func,
  seenAtKey: string.isRequired
};

AdvertisementLeadFlowBanner.defaultProps = {
  isExitIntentModalAvailable: false,
  logAbTestAction: f => f
};

export default AdvertisementLeadFlowBanner;
