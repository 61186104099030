import { attachLoginFlowToTrioButtons } from './attachLoginFlowToTrioButtons';
import {
  loadFacebookJS, loadGoogleJS, loadLoginFlow, loadMicrosotfJS
} from './loadLoginFlow';

export const initLoginFlowTrioButtons = () => {
  const isHomepage = document.querySelector('body.homepage');
  const hasTrioButtons = document.querySelector('.introSection-buttons');
  const hasAnySocialSignupButton = document.querySelector('button.google, button.facebook, button.microsoft');

  if (isHomepage || hasTrioButtons || hasAnySocialSignupButton) {
    loadLoginFlow();
    loadFacebookJS();
    loadGoogleJS();
    loadMicrosotfJS();
    attachLoginFlowToTrioButtons();
  }
};
